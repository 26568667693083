import React, { useEffect, useRef, useState } from "react";
import style from "./AsessmentsCreation.module.css";
import Radio from "@mui/material/Radio";
import { baseUrl } from "../Url";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { TbMessageQuestion, TbUnderline } from "react-icons/tb";
import { Checkbox, Switch, TextField, Tooltip } from "@mui/material";
import {
  MdDelete,
  MdOutlineQuestionAnswer,
  MdAddCircleOutline,
  MdAdd,
  MdFormatBold,
  MdFormatItalic,
  MdFormatStrikethrough,
  MdClose,
  MdOutlineMic,
} from "react-icons/md";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useLocation, useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { IoSettingsOutline } from "react-icons/io5";
import InputAdornment from "@mui/material/InputAdornment";
import { IoMdCloudUpload } from "react-icons/io";
// import Whiteboard from '../WhiteBoard/WhiteBoard';
import { BiClipboard } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import ImageUploader from "react-quill-image-uploader";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import ImageResize from "quill-image-resize";

Quill.register("modules/imageResize", ImageResize);

const AsessmentsCreation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const textFieldRef = useRef(null);

  const state = location.state;
  const adminId = state.admin_Id;
  const orgId = state.org_Id;
  const mainTopicId = state.main_TopicId;
  const topicName = state.topic_Name;
  const topicId = state.topic_Id;

  const [isDisabled, setIsDisabled] = useState(false);
  const [asessmentTypeView, setAsessmentTypeView] = useState(false);
  const [noSelection, setNoSelection] = useState(true);
  const [generationTypeValue, setGenerationTypeValue] = useState("Admin");
  const [asessmentTypeValues, setAsessmentTypevalues] = useState([]);
  const [selectedAsessmentType, setSelectedAsessmentType] = useState("");
  const [fillTheBlankView, setFillTheBlankView] = useState(false);
  const [matchValueView, setMatchValueView] = useState(false);
  const [questionValue, setQuestionValue] = useState("");
  const [options, setOptions] = useState(["", "", "", ""]);
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [ordinary, setOrdinary] = useState(false);
  const [firstOptionSet, setFirstOptionSet] = useState(["", "", "", ""]);
  const [secondOptionSet, setSecondOptionSet] = useState(["", "", "", ""]);
  const [matchAnswerValues, setMatchAnswerValues] = useState(["", "", "", ""]);
  const [ordinaryAnswer, setOrdinaryAnswer] = useState("");
  const [validation, setValidation] = useState(false);
  const [errorValue, setErrorValue] = useState("");
  const [questionCreationSuccess, setQuestionCreationSuccess] = useState(false);
  const [content, setContent] = useState("");
  const [blankAnswers, setBlankAnswers] = useState({});
  const [multipleChoiceView, setMultipeChoice] = useState(false);
  const [selectAnswerView, setSelectAnswerView] = useState(false);
  const [highlightView, setHighlightView] = useState(false);
  const [reWriteView, setReWriteView] = useState(false);
  const [deleteDisable, setDeleteDisable] = useState(false);
  const [aiGeneration, setAiGeneration] = useState(false);
  const [aiGenerationStatus, setAiGenerationStatus] = useState(false);
  const [bold, setBold] = useState(false);
  const [italic, setItalic] = useState(false);
  const [strikeThrough, setStrikeThrough] = useState(false);
  const [underline, setUnderline] = useState(false);
  const [activeButtons, setActiveButtons] = useState({
    bold: false,
    italic: false,
    strikethrough: false,
    underline: false,
  });
  const [isCheckedLearner, setIsCheckedLearner] = useState(false);
  const [isCheckedMentor, setIsCheckedMentor] = useState(false);
  const [isCheckedOther, setIsCheckedOther] = useState(false);
  const [settingView, setSettingView] = useState(false);
  const [correctMark, setCorrectMark] = useState("1");
  const [inCorrectMark, setInCorrectMark] = useState("0");
  const [settingsValidation, setSettingsValidation] = useState(false);
  const [dictationView, setDictationView] = useState(false);
  const [rearrangeWordsView, setRearrangeWordsView] = useState(false);
  const [matchOptionImageView, setMatchOptionImageView] = useState(false);
  const [firstOptionSetImage, setFirstOptionSetImage] = useState([
    "",
    "",
    "",
    "",
  ]);
  const [secondOptionSetImageText, setSecondOptionSetImageText] = useState([
    "",
    "",
    "",
    "",
  ]);
  const [imageMatchAnswerValues, setImageMatchAnswerValues] = useState([
    "",
    "",
    "",
    "",
  ]);
  // const[whiteBoradShow,setWhiteBoardShow]=useState(false)
  // const[whiteBoradIconShow,setWhiteBoardIconShow]=useState(true)
  const [crossWordPuzzle, setCrossWordPuzzle] = useState(false);
  const [closing, setClosing] = useState(false);
  const [recordings, setRecordings] = useState([
    { audio: null, mediaRecorder: null, isRecording: false, answer: "" },
  ]);
  const [grid, setGrid] = useState(
    Array.from({ length: 10 }, () =>
      Array.from({ length: 10 }, () => ({
        cluevalue: "",
        clueNumber: null,
        clueID: [],
      }))
    )
  );
  const [cluesAcross, setCluesAcross] = useState([
    { id: "1027277301", number: 1, clue: "", answer: "" },
  ]);
  const [cluesDown, setCluesDown] = useState([
    { id: "1037101023", number: 1, clue: "", answer: "" },
  ]);
  const [selectingCell, setSelectingCell] = useState(false);
  const [selectedClueIndex, setSelectedClueIndex] = useState(null);
  const [selectedDirection, setSelectedDirection] = useState(null);
  const [assessmentStatus, setAssessmentStatus] = useState("draft");
  const [assessmentLevel, setAssessmentLevel] = useState("easy");
  const [mediaCollection, setMediaCollection] = useState([]);
  const [mediaPopup, setMediaPopup] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedImageOptionIndex, setSelectedImageOptionIndex] =
    useState(null);
  const [explanation, setExplanation] = useState("");

  const [reArrangeOptionSet, setReArrangeOptionSet] = useState([
    "",
    "",
    "",
    "",
  ]);
  const [reArrangeAnswerValues, setReArrangeAnswerValues] = useState([
    "",
    "",
    "",
    "",
  ]);
  const [selectContent, setSelectContent] = useState("");
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [isDropdownAdded, setIsDropdownAdded] = useState(false);
  const selectTextFieldRef = useRef(null);
  const [correctOption, setCorrectOption] = useState(null);
  const [dropdowns, setDropdowns] = useState([]);
  const [correctOptions, setCorrectOptions] = useState({});
  const [highlightContent, setHighlightContent] = useState("");
  const highlightRef = useRef(null);
  const [lastSelectedText, setLastSelectedText] = useState(null);
  const [reWriteAnswer, setReWriteAnswer] = useState("");

  const modules = {
    toolbar: {
      container: [
        [{ header: "1" }, { header: "2" }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
        ["clean"],
        [{ color: [] }, { background: [] }],
      ],
      handlers: {
        image: ImageUploader.handler,
      },
    },
    imageResize: {},
  };

  const formats = [
    "header",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "image",
    "color",
    "background",
  ];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  //Generation type selection

  const handleGenerationTypeChange = (value) => {
    setGenerationTypeValue(value);
    console.log(value);
    if (value === "Admin") {
      adminAsessmentType();
      setAsessmentTypeView(true);
      setNoSelection(false);
      setAiGeneration(false);
    } else if (value === "Ai") {
      setAsessmentTypeView(false);
      setNoSelection(false);
      setOptions(["", "", "", ""]);
      setQuestionValue("");
      setCorrectAnswers([]);
      setFirstOptionSet(["", "", "", ""]);
      setSecondOptionSet(["", "", "", ""]);
      setContent("");
      setBlankAnswers({});
      setMatchAnswerValues(["", "", "", ""]);
      setOrdinaryAnswer("");
      setFillTheBlankView(false);
      setOrdinary(false);
      setMatchValueView(false);
      setMultipeChoice(false);
      setAiGeneration(true);
      setDictationView(false);
      setMatchOptionImageView(false);
      setCrossWordPuzzle(false);
    }
  };

  //Assesment type based handling

  const handleAsessmentTypeChange = (event) => {
    setSelectedAsessmentType(event.target.value);
    console.log(selectedAsessmentType);
    setOptions(["", "", "", ""]);
    setQuestionValue("");
    setCorrectAnswers([]);
    setFirstOptionSet(["", "", "", ""]);
    setSecondOptionSet(["", "", "", ""]);
    setContent("");
    setBlankAnswers({});
    setOrdinaryAnswer("");
    setRecordings([
      { audio: null, mediaRecorder: null, isRecording: false, answer: "" },
    ]);
    setGrid(
      Array.from({ length: 10 }, () =>
        Array.from({ length: 10 }, () => ({
          cluevalue: "",
          clueNumber: null,
          clueID: [],
        }))
      )
    );
    // Reset cluesAcross to its initial state
    setCluesAcross([{ id: "1027277301", number: 1, clue: "", answer: "" }]);
    // Reset cluesDown to its initial state
    setCluesDown([{ id: "1037101023", number: 1, clue: "", answer: "" }]);
    setExplanation("");

    console.log(event.target.value);
    if (event.target.value === "fill in the blanks") {
      setFillTheBlankView(true);
      setOrdinary(false);
      setMatchValueView(false);
      setMultipeChoice(false);
      setDictationView(false);
      setMatchOptionImageView(false);
      setCrossWordPuzzle(false);
      setRearrangeWordsView(false);
      setSelectAnswerView(false);
      setHighlightView(false);
      setReWriteView(false);
    } else if (event.target.value === "multiple choices") {
      setFillTheBlankView(false);
      setOrdinary(false);
      setMatchValueView(false);
      setMultipeChoice(true);
      setDictationView(false);
      setMatchOptionImageView(false);
      setCrossWordPuzzle(false);
      setRearrangeWordsView(false);
      setSelectAnswerView(false);
      setHighlightView(false);
      setReWriteView(false);
    } else if (event.target.value === "ordinary") {
      setFillTheBlankView(false);
      setOrdinary(true);
      setMatchValueView(false);
      setMultipeChoice(false);
      setDictationView(false);
      setMatchOptionImageView(false);
      setCrossWordPuzzle(false);
      setRearrangeWordsView(false);
      setSelectAnswerView(false);
      setHighlightView(false);
      setReWriteView(false);
    } else if (event.target.value === "Match the following") {
      setMatchValueView(true);
      setFillTheBlankView(false);
      setOrdinary(false);
      setMultipeChoice(false);
      setDictationView(false);
      setMatchOptionImageView(false);
      setCrossWordPuzzle(false);
      setRearrangeWordsView(false);
      setSelectAnswerView(false);
      setHighlightView(false);
      setReWriteView(false);
    } else if (event.target.value === "dictation") {
      setDictationView(true);
      setMatchValueView(false);
      setFillTheBlankView(false);
      setOrdinary(false);
      setMultipeChoice(false);
      setMatchOptionImageView(false);
      setCrossWordPuzzle(false);
      setRearrangeWordsView(false);
      setSelectAnswerView(false);
      setHighlightView(false);
      setReWriteView(false);
    } else if (event.target.value === "Match the following with image") {
      setDictationView(false);
      setMatchValueView(false);
      setFillTheBlankView(false);
      setOrdinary(false);
      setMultipeChoice(false);
      setMatchOptionImageView(true);
      setCrossWordPuzzle(false);
      setRearrangeWordsView(false);
      setSelectAnswerView(false);
      setHighlightView(false);
      setReWriteView(false);
    } else if (event.target.value === "cross word puzzle") {
      setCrossWordPuzzle(true);
      setDictationView(false);
      setMatchValueView(false);
      setFillTheBlankView(false);
      setOrdinary(false);
      setMultipeChoice(false);
      setMatchOptionImageView(false);
      setRearrangeWordsView(false);
      setSelectAnswerView(false);
      setHighlightView(false);
      setReWriteView(false);
    } else if (event.target.value === "Rearrange") {
      setDictationView(false);
      setMatchValueView(false);
      setFillTheBlankView(false);
      setOrdinary(false);
      setMultipeChoice(false);
      setMatchOptionImageView(false);
      setCrossWordPuzzle(false);
      setRearrangeWordsView(true);
      setSelectAnswerView(false);
      setHighlightView(false);
      setReWriteView(false);
    } else if (event.target.value === "select the answers") {
      setDictationView(false);
      setMatchValueView(false);
      setFillTheBlankView(false);
      setOrdinary(false);
      setMultipeChoice(false);
      setMatchOptionImageView(false);
      setCrossWordPuzzle(false);
      setRearrangeWordsView(false);
      setSelectAnswerView(true);
      setHighlightView(false);
      setReWriteView(false);
    } else if (event.target.value === "highlight") {
      setDictationView(false);
      setMatchValueView(false);
      setFillTheBlankView(false);
      setOrdinary(false);
      setMultipeChoice(false);
      setMatchOptionImageView(false);
      setCrossWordPuzzle(false);
      setRearrangeWordsView(false);
      setSelectAnswerView(false);
      setHighlightView(true);
      setReWriteView(false);
    } else if (event.target.value === "Rewrite") {
      setDictationView(false);
      setMatchValueView(false);
      setFillTheBlankView(false);
      setOrdinary(false);
      setMultipeChoice(false);
      setMatchOptionImageView(false);
      setCrossWordPuzzle(false);
      setRearrangeWordsView(false);
      setSelectAnswerView(false);
      setHighlightView(false);
      setReWriteView(true);
    } else {
      setDictationView(false);
      setMatchValueView(false);
      setFillTheBlankView(false);
      setOrdinary(false);
      setMultipeChoice(false);
      setMatchOptionImageView(false);
      setCrossWordPuzzle(false);
      setRearrangeWordsView(false);
      setSelectAnswerView(false);
      setHighlightView(false);
      setReWriteView(false);
    }
  };

  const handleExplanationChange = (html) => {
    setExplanation(html);
  };

  //setting comment toggle
  const handleLearnerSwitchChange = () => {
    setIsCheckedLearner(!isCheckedLearner);
  };
  const handleMentorSwitchChange = () => {
    setIsCheckedMentor(!isCheckedMentor);
  };
  const handleOtherSwitchChange = () => {
    setIsCheckedOther(!isCheckedOther);
  };

  //Fill the blank question handling sections {

  //---Blank Creation

  const quillRef = useRef(null);

  const handleAddBlankClick = () => {
    let newBlankCount = 1;
    if (Object.keys(blankAnswers).length > 0) {
      const maxBlankNumber = Math.max(...Object.keys(blankAnswers).map(Number));
      newBlankCount = maxBlankNumber + 1;
    }

    const quillEditor = quillRef.current.getEditor();
    const range = quillEditor.getSelection(); // Get the selection range

    if (range) {
      const newContent = `[blank ${newBlankCount}]`;
      quillEditor.insertText(range.index, newContent); // Insert blank at current cursor position
      setBlankAnswers((prevAnswers) => ({
        ...prevAnswers,
        [newBlankCount]: "",
      }));
    }
  };
  //Question value formatting

  const handleToggleBold = (button) => {
    setActiveButtons((prev) => ({ ...prev, [button]: !prev[button] }));
    setBold(!bold);
  };

  const handleToggleItalic = (button) => {
    setActiveButtons((prev) => ({ ...prev, [button]: !prev[button] }));

    setItalic(!italic);
  };

  const handleToggleStrikeThrough = (button) => {
    setActiveButtons((prev) => ({ ...prev, [button]: !prev[button] }));

    setStrikeThrough(!strikeThrough);
  };
  const handleToggleUnderline = (button) => {
    setActiveButtons((prev) => ({ ...prev, [button]: !prev[button] }));

    setUnderline(!underline);
  };

  //-----Blank Answer handling

  const handleBlankAnswerChange = (blankNumber, answer) => {
    setBlankAnswers((prevAnswers) => ({
      ...prevAnswers,
      [blankNumber]: answer,
    }));
  };

  //---Blank Deletion

  const handleDeleteBlank = (blankNumber) => {
    setBlankAnswers((prevAnswers) => {
      const updatedAnswers = { ...prevAnswers };
      delete updatedAnswers[blankNumber];
      return updatedAnswers;
    });

    const quillEditor = quillRef.current.getEditor();
    const currentContent = quillEditor.getText();
    const updatedContent = currentContent.replace(`[blank ${blankNumber}]`, "");
    setContent(updatedContent);
    quillEditor.setText(updatedContent);
  };

  //----Space key clear handling

  const handleKeyDown = (e) => {
    if (e.key === "Backspace") {
      const selectionStart = textFieldRef.current.selectionStart;
      const selectionEnd = textFieldRef.current.selectionEnd;
      Object.keys(blankAnswers).forEach((blankNumber) => {
        const blankPosition = content.indexOf(`[blank ${blankNumber}]`);
        if (
          blankPosition !== -1 &&
          blankPosition === selectionStart &&
          selectionStart === selectionEnd
        ) {
          handleDeleteBlank(blankNumber);
        }
      });
    }
  };

  //---Blur handling

  const handleBlur = () => {
    if (content.trim() === "") {
      setBlankAnswers({});
    }
  };

  //clearing functionality

  const handleBlankQuestionClear = () => {
    // setBlankAnswers({});
    // setContent("");
    setExplanation("");
  };

  //---Assessment Data Creation

  const handleFilltheBlankQuestionCreation = () => {
    const trimmedBlankAnswers = Object.fromEntries(
      Object.entries(blankAnswers).map(([key, value]) => [key, value.trim()])
    );
    const asessmentData = [
      {
        question: content,
        type: selectedAsessmentType,
        options: "",
        answer: trimmedBlankAnswers,
      },
    ];
    console.log(asessmentData);
    if (content === "") {
      setErrorValue("Question is missing");
      setValidation(true);
    } else if (Object.keys(blankAnswers).length === 0) {
      setErrorValue("Please enter the blank value");
      setValidation(true);
    } else if (correctMark.trim() === "") {
      setErrorValue("Mark is missing");
      setValidation(true);
    } else if (inCorrectMark.trim() === "") {
      setErrorValue("Incorrect mark is missing");
      setValidation(true);
    } else {
      assessmentCreate(asessmentData);
    }
  };

  //}

  //Multiple Choice question handling sections{

  //Question Handling

  const handleMultipleQuestionChange = (html) => {
    setQuestionValue(html);
  };

  //----Answer Handling

  const handleMultipleAnsweChange = (index, value) => {
    setOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index] = value;
      return updatedOptions;
    });
  };

  //----Check box value handling
  // Answers to include Multiple selected index
  const handleCheckboxChange = (index) => {
    setCorrectAnswers((prevCorrectAnswers) => {
      if (prevCorrectAnswers.includes(index)) {
        return prevCorrectAnswers.filter((i) => i !== index);
      } else {
        return [...new Set([...prevCorrectAnswers, index])];
      }
    });
  };

  // Reset correctAnswers to include only the selected index
  // const handleCheckboxChange = (index) => {
  //   setCorrectAnswers([index]);
  // };

  //----Option set creation

  const handleMultipleOptionAdd = () => {
    setOptions((prevOptions) => [...prevOptions, ""]);
  };

  //----Option set deletion

  const handleMultipleOptionDelete = (index) => {
    if (options.length > 2) {
      setOptions((prevOptions) => prevOptions.filter((_, i) => i !== index));
    } else {
      setDeleteDisable(true);
    }
  };

  //clear section

  const handleMultipleQuestionClear = () => {
    setQuestionValue("");
    setOptions(["", "", "", ""]);
    setCorrectAnswers([]);
    setExplanation("");
  };

  //---Assessment Data Creation

  const handleMulitipleQuestionCreation = () => {
    const trimmedOptions = options.map((option) => option.trim());
    const asessmentData = [
      {
        question: questionValue,
        type: selectedAsessmentType,
        options: trimmedOptions.filter((option) => option.trim() !== ""),
        answer: correctAnswers.map((index) => trimmedOptions[index]),
      },
    ];
    console.log(asessmentData);
    if (questionValue === "") {
      setErrorValue("Question is missing");
      setValidation(true);
    } else if (trimmedOptions.filter((option) => option !== "").length === 0) {
      setErrorValue("Options is missing");
      setValidation(true);
    } else if (correctAnswers.length === 0) {
      setErrorValue("Answer list is missing");
      setValidation(true);
    } else if (correctMark.trim() === "") {
      setErrorValue("Mark is missing");
      setValidation(true);
    } else if (inCorrectMark.trim() === "") {
      setErrorValue("Incorrect mark is missing");
      setValidation(true);
    } else {
      assessmentCreate(asessmentData);
    }
  };

  //}

  //Ordinary question handling sections{

  //Question handling
  const handleOrdinaryQuestionChange = (html) => {
    setQuestionValue(html);
  };

  const handleOrdinaryAnswerChange = (e) => {
    let sanitizedValue = e.target.value
      .replace(/‘|’/g, "'") 
      .replace(/“|”/g, '"');

    setOrdinaryAnswer(sanitizedValue);
  };

  //clear section

  const handleOrdinaryQuestionClear = () => {
    setQuestionValue("");
    setOrdinaryAnswer("");
    setExplanation("");
  };

  //---Assessment Data Creation

  const handleOrdinaryQuestionCreation = () => {
    const trimmedAnswer = ordinaryAnswer.trim();
    const asessmentData = [
      {
        question: questionValue,
        type: selectedAsessmentType,
        options: "",
        answer: trimmedAnswer,
      },
    ];
    console.log(asessmentData);
    if (questionValue === "") {
      setErrorValue("Question is missing");
      setValidation(true);
    } else if (ordinaryAnswer.length === 0) {
      setErrorValue("Answer is missing");
      setValidation(true);
    } else if (correctMark.trim() === "") {
      setErrorValue("Mark is missing");
      setValidation(true);
    } else if (inCorrectMark.trim() === "") {
      setErrorValue("Incorrect mark is missing");
      setValidation(true);
    } else {
      assessmentCreate(asessmentData);
    }
  };

  // }

  //Match the following question handling sections{

  const handleMatchQuestionChange = (html) => {
    setQuestionValue(html);
  };

  //----First Option Set handling

  const handleFirstOptionSet = (index, value) => {
    setFirstOptionSet((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index] = value;
      return updatedOptions;
    });
  };

  //-----Second Option Set handling

  const handleSecondOptionSet = (index, value) => {
    setSecondOptionSet((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index] = value;
      return updatedOptions;
    });
  };

  //----Answer set handling

  const handleMatchAnswerKey = (index, value) => {
    setMatchAnswerValues((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index] = value;
      return updatedOptions;
    });
  };

  //----Options Add

  const handleMatchOptionAdd = (type) => {
    if (type === "first") {
      setFirstOptionSet((prevOptions) => [...prevOptions, ""]);
    } else if (type === "second") {
      setSecondOptionSet((prevOptions) => [...prevOptions, ""]);
    }
  };

  //---Option Deletion

  const handleDeleteMatchOption = (index, type) => {
    const minOptionSetLength = firstOptionSet.length;

    if (
      (type === "first" && minOptionSetLength > 2) ||
      (type === "second" && secondOptionSet.length > minOptionSetLength)
    ) {
      if (type === "first") {
        setFirstOptionSet((prevOptions) =>
          prevOptions.filter((_, i) => i !== index)
        );
      } else if (type === "second") {
        setSecondOptionSet((prevOptions) =>
          prevOptions.filter((_, i) => i !== index).slice(0, minOptionSetLength)
        );
      }
    }
  };

  //----Answer Add

  const handleAddMatchAnswer = () => {
    if (matchAnswerValues.length < firstOptionSet.length) {
      setMatchAnswerValues((prevOptions) => [...prevOptions, ""]);
    } else {
      console.log("Maximum number of answers reached");
    }
  };

  //---Answer Deletion

  const handleDeleteMatchAnswer = (index) => {
    const minAnswerLength = firstOptionSet.length;

    if (matchAnswerValues.length > minAnswerLength) {
      setMatchAnswerValues((prevOptions) =>
        prevOptions.filter((_, i) => i !== index)
      );
    }
  };

  //clear section

  const handlematchQuestionClear = () => {
    setFirstOptionSet(["", "", "", ""]);
    setSecondOptionSet(["", "", "", ""]);
    setMatchAnswerValues(["", "", "", ""]);
    setQuestionValue("");
    setExplanation("");
  };

  //---Assessment Data Creation

  const handleMatchQuestionCreation = () => {
    const asessmentData = [
      {
        left: firstOptionSet.map((option) => option.trim()),
        question: questionValue,
        type: selectedAsessmentType,
        right: secondOptionSet.map((option) => option.trim()),
        options: [],
        answer: matchAnswerValues.map((option) => option.trim()),
      },
    ];
    console.log(asessmentData);
    if (questionValue.trim() === "") {
      setErrorValue("Question is missing");
      setValidation(true);
    } else if (firstOptionSet.every((option) => option.trim() === "")) {
      setErrorValue("First option set is missing");
      setValidation(true);
    } else if (secondOptionSet.every((option) => option.trim() === "")) {
      setErrorValue("Second option set is missing");
      setValidation(true);
    } else if (matchAnswerValues.every((answer) => answer.trim() === "")) {
      setErrorValue("Answer list is missing");
      setValidation(true);
    } else if (correctMark.trim() === "") {
      setErrorValue("Mark is missing");
      setValidation(true);
    } else if (inCorrectMark.trim() === "") {
      setErrorValue("Incorrect mark is missing");
      setValidation(true);
    } else {
      assessmentCreate(asessmentData);
    }
  };

  //Dictation question handling sections{

  //Question Handling

  const handleDictaionQuestionChange = (html) => {
    setQuestionValue(html);
  };

  const handleAddRecording = () => {
    setRecordings([
      ...recordings,
      { audio: null, mediaRecorder: null, isRecording: false, answer: "" },
    ]);
  };

  const handleDeleteRecording = (index) => {
    const updatedRecordings = [...recordings];
    updatedRecordings.splice(index, 1);
    setRecordings(updatedRecordings);
  };

  const handleAnswerChange = (index, answer) => {
    const updatedRecordings = [...recordings];
    updatedRecordings[index].answer = answer;
    setRecordings(updatedRecordings);
  };

  const toggleRecording = (index) => {
    const updatedRecordings = [...recordings];
    updatedRecordings[index].isRecording = !recordings[index].isRecording;
    setRecordings(updatedRecordings);
  };

  const handleStartStopRecording = async (index) => {
    const isRecording = recordings[index].isRecording;
    if (!isRecording) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        const mediaRecorder = new MediaRecorder(stream);
        const chunks = [];

        mediaRecorder.ondataavailable = (e) => {
          chunks.push(e.data);
        };

        mediaRecorder.onstop = async () => {
          const blob = new Blob(chunks, { type: "audio/wav" });

          // Send the audio blob to the backend
          const formData = new FormData();
          formData.append("audio", blob);
          try {
            const response = await fetch(`${baseUrl}/voice/upload/new`, {
              method: "POST",
              body: formData,
            });
            if (!response.ok) {
              throw new Error("Failed to upload audio");
            }
            const responseData = await response.json();
            const audioUrl = responseData.data[0].url;

            // Update the recordings state with the received audio URL
            const updatedRecordings = [...recordings];
            updatedRecordings[index].audio = audioUrl;
            updatedRecordings[index].mediaRecorder = null;
            setRecordings(updatedRecordings);
          } catch (error) {
            console.error("Error uploading audio:", error);
          }
        };

        mediaRecorder.start();
        toggleRecording(index);
        const updatedRecordings = [...recordings];
        updatedRecordings[index].mediaRecorder = mediaRecorder;
        setRecordings(updatedRecordings);
      } catch (error) {
        console.error("Error accessing microphone:", error);
      }
    } else {
      const mediaRecorder = recordings[index].mediaRecorder;
      mediaRecorder.stop();
      toggleRecording(index);
    }
  };

  const handleDictationQuestionClear = () => {
    setRecordings([
      { audio: null, mediaRecorder: null, isRecording: false, answer: "" },
    ]);
    setQuestionValue("");
  };

  const handleDictationQuestionCreation = () => {
    const asessmentData = [
      {
        left: recordings.map((recording) => recording.audio),
        question: questionValue,
        type: selectedAsessmentType,
        right: [],
        options: [],
        answer: recordings.map((recording) => recording.answer),
      },
    ];
    console.log(asessmentData);
    if (questionValue.trim() === "") {
      setErrorValue("Question is missing");
      setValidation(true);
    } else if (recordings.some((recording) => !recording.audio)) {
      setErrorValue("Audio is missing");
      setValidation(true);
    } else if (recordings.some((recording) => !recording.answer)) {
      setErrorValue("Answer is missing");
      setValidation(true);
    } else if (correctMark.trim() === "") {
      setErrorValue("Mark is missing");
      setValidation(true);
    } else if (inCorrectMark.trim() === "") {
      setErrorValue("Incorrect mark is missing");
      setValidation(true);
    } else {
      assessmentCreate(asessmentData);
    }
  };

  //match the following with image

  //Match the following question handling sections{

  const handleImageMatchQuestionChange = (html) => {
    setQuestionValue(html);
  };

  //----First Option Set handling

  const handleImageFirstOptionSet = async (index, file) => {
    if (typeof file === "string") {
      setFirstOptionSetImage((prevImages) => {
        const updatedImages = [...prevImages];
        updatedImages[index] = file;
        return updatedImages;
      });
    } else {
      const formData = new FormData();
      formData.append("audio", file);

      try {
        const response = await fetch(`${baseUrl}/voice/upload/new`, {
          method: "POST",
          body: formData,
        });

        if (response.ok) {
          const responseData = await response.json(); // Assuming the API returns the URL in JSON format
          const imageUrl = responseData.data[0].url;
          setFirstOptionSetImage((prevImages) => {
            const updatedImages = [...prevImages];
            updatedImages[index] = imageUrl;
            return updatedImages;
          });
        } else {
          // Handle error response from the API
          console.error("Failed to upload image", response);
        }
      } catch (error) {
        // Handle network errors or other exceptions
        console.error("Error uploading image:", error);
      }
    }
  };

  const handleImageReplace = (index) => {
    const input = document.getElementById(`fileInput-${index}`);
    input.click();
  };

  const handleReplaceImage = (index) => {
    setSelectedImageOptionIndex(index);
    setMediaPopup(true);
    filesGet();
    // Trigger the file input click event
    // document.getElementById(`fileInput_${index}`).click();
  };
  //-----Second Option Set handling

  const handleImageSecondOptionSet = (index, value) => {
    setSecondOptionSetImageText((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index] = value;
      return updatedOptions;
    });
  };

  //----Answer set handling

  const handleImageMatchAnswerKey = (index, value) => {
    setImageMatchAnswerValues((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index] = value;
      return updatedOptions;
    });
  };

  //----Options Add

  const handleImageMatchOptionAdd = (type) => {
    if (type === "first") {
      setFirstOptionSetImage((prevOptions) => [...prevOptions, ""]);
    } else if (type === "second") {
      setSecondOptionSetImageText((prevOptions) => [...prevOptions, ""]);
    }
  };

  //---Option Deletion

  const handleDeleteImageMatchOption = (index, type) => {
    const minOptionSetLength = firstOptionSetImage.length;

    if (
      (type === "first" && minOptionSetLength > 2) ||
      (type === "second" &&
        secondOptionSetImageText.length > minOptionSetLength)
    ) {
      if (type === "first") {
        setFirstOptionSetImage((prevOptions) =>
          prevOptions.filter((_, i) => i !== index)
        );
      } else if (type === "second") {
        setSecondOptionSetImageText((prevOptions) =>
          prevOptions.filter((_, i) => i !== index).slice(0, minOptionSetLength)
        );
      }
    }
  };

  //----Answer Add

  const handleAddImageMatchAnswer = () => {
    if (imageMatchAnswerValues.length < firstOptionSetImage.length) {
      setImageMatchAnswerValues((prevOptions) => [...prevOptions, ""]);
    } else {
      console.log("Maximum number of answers reached");
    }
  };

  //---Answer Deletion

  const handleDeleteImageMatchAnswer = (index) => {
    const minAnswerLength = firstOptionSetImage.length;

    if (imageMatchAnswerValues.length > minAnswerLength) {
      setImageMatchAnswerValues((prevOptions) =>
        prevOptions.filter((_, i) => i !== index)
      );
    }
  };

  //clear section

  const handlematchImageQuestionClear = () => {
    setFirstOptionSetImage(["", "", "", ""]);
    setSecondOptionSetImageText(["", "", "", ""]);
    setImageMatchAnswerValues(["", "", "", ""]);
    setQuestionValue("");
    setExplanation("");
  };

  //---Assessment Data Creation

  const handleImageMatchQuestionCreation = () => {
    const asessmentData = [
      {
        left: firstOptionSetImage.map((option) => option.trim() ),
        question: questionValue,
        type: selectedAsessmentType,
        right: secondOptionSetImageText.map(
          (option) => option.trim() 
        ),
        options: [],
        answer: imageMatchAnswerValues.map((option) => option.trim()),
      },
    ];

    console.log(asessmentData);

    if (questionValue.trim() === "") {
      setErrorValue("Question is missing");
      setValidation(true);
    } else if (
      firstOptionSetImage.length === 0 ||
      firstOptionSetImage.some((option) => option.trim() === "")
    ) {
      setErrorValue("First option set is missing ");
      setValidation(true);
    } else if (
      secondOptionSetImageText.length === 0 ||
      secondOptionSetImageText.some((option) => option.trim() === "")
    ) {
      setErrorValue("Second option set is missing ");
      setValidation(true);
    } else if (
      imageMatchAnswerValues.length === 0 ||
      imageMatchAnswerValues.some((answer) => answer.trim() === "")
    ) {
      setErrorValue("Answer list is missing ");
      setValidation(true);
    } else if (correctMark.trim() === "") {
      setErrorValue("Mark is missing");
      setValidation(true);
    } else if (inCorrectMark.trim() === "") {
      setErrorValue("Incorrect mark is missing");
      setValidation(true);
    } else {
      assessmentCreate(asessmentData);
    }
  };

  //cross word puzzle

  //Question Handling

  const handleCrossWordPuzzleQuestionChange = (html) => {
    setQuestionValue(html);
  };

  //----Answer Handling

  const handleCrossWordPuzzleinputChange = (e, row, col) => {
    const newGrid = [...grid];
    newGrid[row][col] = {
      ...newGrid[row][col], // Preserve existing properties
      cluevalue: e.target.value, // Update the cell value
    };
    setGrid(newGrid);
  };

  //clear section

  const handleCrossWordPuzzleQuestionClear = () => {
    setQuestionValue("");
    setOptions(["", "", "", ""]);
    setCorrectAnswers([]);
    setExplanation("");
    setCluesDown([{ id: "1037101023", number: 1, clue: "", answer: "" },]);
    setCluesAcross([{ id: "1027277301", number: 1, clue: "", answer: "" },]);
    setGrid(
      Array.from({ length: 10 }, () =>
        Array.from({ length: 10 }, () => ({
          cluevalue: "",
          clueNumber: null,
          clueID: [],
        }))
      )
    );
  };

  const generateUniqueId = () => {
    const timestamp = new Date().getTime(); // Get current timestamp
    const randomId = Math.random().toString(36).substring(2, 15); // Generate random number
    return `${timestamp}-${randomId}`; // Combine timestamp and random number
  };

  const addClue = (direction) => {
    const lastEntryNumber = direction === "across"
      ? (cluesAcross.length > 0 ? cluesAcross[cluesAcross.length - 1].number : 0)
     : (cluesDown.length > 0 ? cluesDown[cluesDown.length - 1].number : 0);
    const newNumber = lastEntryNumber + 1;
    const newId = generateUniqueId(); // Generate a unique ID for the clue
    const newClue = { id: newId, number: newNumber, clue: "", answer: "" };
    if (direction === "across") {
      setCluesAcross([...cluesAcross, newClue]);
    } else if (direction === "down") {
      setCluesDown([...cluesDown, newClue]);
    }
  };

  const removeClue = (direction, id) => {
    // Find the changes before and after changes in the Array
    let updatedClues = [];
    if (direction === "across") {
      const previousCluesAcross = [...cluesAcross];
      setCluesAcross(cluesAcross.filter((clue) => clue.id !== id));
      updatedClues = previousCluesAcross.map((prevClue) => {
        const updatedClue = cluesAcross.find((clue) => clue.id === prevClue.id);
        return {
          id: prevClue.id,
          number_before: prevClue.number,
          number_after: updatedClue ? updatedClue.number : null, 
        };
      });
    } else if (direction === "down") {
      const previousCluesDown = [...cluesDown];
      setCluesDown(cluesDown.filter((clue) => clue.id !== id));
      updatedClues = previousCluesDown.map((prevClue) => {
        const updatedClue = cluesDown.find((clue) => clue.id === prevClue.id);
        return {
          id: prevClue.id,
          number_before: prevClue.number,
          number_after: updatedClue ? updatedClue.number : null, 
        };
      });
    }
    
    // Fetch items which has changes in 'number' value
    const changedClues = updatedClues
    .filter(
      (clue) => clue.number_before !== clue.number_after
    )
    .filter((clue) => clue.number_after !== null);

  // Update the grid by removing only the specified clue ID
  const updatedGrid = [...grid];
  updatedGrid.forEach((row, rowIndex) => {
    row.forEach((cell, colIndex) => {
      if (cell.clueID.includes(id)) {
        const updatedClueIDs = cell.clueID.filter((clueId) => clueId !== id);
        updatedGrid[rowIndex][colIndex] = {
          ...cell,
          clueNumber : null,
          clueID: updatedClueIDs, 
        };
        if (updatedClueIDs.length === 0) {
          updatedGrid[rowIndex][colIndex].cluevalue = "";
          updatedGrid[rowIndex][colIndex].clueNumber = null;
        }
      }
    });
  });

  // Iterate through the grid and update clueNumber based on changedClues
  const updatedGridWithClueNumbers = updatedGrid.map((row) =>
    row.map((cell) => {
      const matchingClue = changedClues.find(
        (clue) => cell.clueID.includes(clue.id) && cell.clueNumber === clue.number_before
      );
      if (matchingClue) {
        return {
          ...cell,
          clueNumber: matchingClue.number_after,
        };
      }
      return cell;
    })
  );

  // Set the updated grid
  setGrid(updatedGridWithClueNumbers);
};

  const handleClueChange = (e, index, direction) => {
    const updatedClues =
      direction === "across" ? [...cluesAcross] : [...cluesDown];
    updatedClues[index].clue = e.target.value;
    if (direction === "across") {
      setCluesAcross(updatedClues);
    } else if (direction === "down") {
      setCluesDown(updatedClues);
    }
  };

  // Function to handle click on the button to start selecting a cell for the clue
  const startSelectingCell = (index, direction, id) => {
    const clues = direction === "across" ? cluesAcross : cluesDown;
    const clueObj = clues[index];

    // Check if the clue and its answer are not empty
    if (!clueObj.clue || !clueObj.answer) {
      // Show error message and prevent selecting the starting cell
      setErrorValue(
        "Please enter both clue and answer before selecting the starting cell."
      );
      setValidation(true);
      return;
    }

    setSelectingCell(true);
    setSelectedClueIndex(index);
    setSelectedDirection(direction);
  };

  // Function to handle click on the crossword grid cells
  const handleCellClick = (rowIndex, colIndex) => {
    const prevClueId =
      selectedDirection === "across"
        ? cluesAcross[selectedClueIndex]?.id
        : cluesDown[selectedClueIndex]?.id;
    const prevClueNumber =
      selectedDirection === "across"
        ? cluesAcross[selectedClueIndex]?.number
        : cluesDown[selectedClueIndex]?.number;

    // Clear the cells associated with the previous selection
    if (prevClueId && prevClueNumber && selectedDirection) {
      const updatedGrid = [...grid];

      updatedGrid.forEach((row, rIndex) => {
        row.forEach((cell, cIndex) => {
          if (cell.clueID.includes(prevClueId)) {
            updatedGrid[rIndex][cIndex] = {
              ...cell,
              clueID: cell.clueID.filter((id) => id !== prevClueId), // Remove only the current clue ID
            };

            // Clear the value and number if no clue IDs remain
            if (updatedGrid[rIndex][cIndex].clueID.length === 0) {
              updatedGrid[rIndex][cIndex].cluevalue = "";
              updatedGrid[rIndex][cIndex].clueNumber = null;
            }
          }
        });
      });

      setGrid(updatedGrid);
    }

    if (
      selectingCell &&
      selectedClueIndex !== null &&
      selectedDirection !== null
    ) {
      const updatedGrid = [...grid];
      const clueObj =
        selectedDirection === "across"
          ? cluesAcross[selectedClueIndex]
          : cluesDown[selectedClueIndex];
      const answerLength = clueObj.answer.length;
      let remainingCells;

      // Calculate the remaining cells based on the direction
      if (selectedDirection === "across") {
        remainingCells = updatedGrid[0].length - rowIndex; // Calculate remaining cells to the right
      } else {
        remainingCells = updatedGrid.length - colIndex; // Calculate remaining cells below
      }

      // Check if there's enough space to display the entire answer
      if (answerLength > remainingCells) {
        // Set validation state and error message
        setValidation(true);
        setErrorValue("Not enough space to display the entire answer");
        return; // Exit the function
      }

      // Update the grid to mark the starting cell for the clue's answer
      if (selectedDirection === "across") {
        for (let i = 0; i < answerLength; i++) {
          if (rowIndex + i < updatedGrid.length) {
            updatedGrid[rowIndex + i][colIndex] = {
              ...updatedGrid[rowIndex + i][colIndex],
              cluevalue: clueObj.answer[i].toUpperCase(),
              clueNumber:
                i === 0
                  ? clueObj.number
                  : updatedGrid[rowIndex + i][colIndex].clueNumber,
              clueID: [
                ...updatedGrid[rowIndex + i][colIndex].clueID,
                clueObj.id,
              ],
            };
          }
        }
      } else {
        for (let i = 0; i < answerLength; i++) {
          if (colIndex + i < updatedGrid.length) {
            updatedGrid[rowIndex][colIndex + i] = {
              ...updatedGrid[rowIndex][colIndex + i],
              cluevalue: clueObj.answer[i].toUpperCase(),
              clueNumber:
                i === 0
                  ? clueObj.number
                  : updatedGrid[rowIndex][colIndex + i].clueNumber, // Set clue number in starting cell
              clueID: [
                ...updatedGrid[rowIndex][colIndex + i].clueID,
                clueObj.id,
              ], // Add new clue ID to the array
            };
          }
        }
      }

      setGrid(updatedGrid);
      setSelectingCell(false);
      setSelectedClueIndex(null);
      setSelectedDirection(null);
    }
  };

  const handleClueAnswerChange = (e, index, direction) => {
    const updatedClues =
      direction === "across" ? [...cluesAcross] : [...cluesDown];
    const newAnswer = e.target.value;

    // Update the clue's answer
    updatedClues[index].answer = newAnswer;
    if (direction === "across") {
      setCluesAcross(updatedClues);
    } else if (direction === "down") {
      setCluesDown(updatedClues);
    }

    // Update the grid cells corresponding to the clue's answer
    const updatedGrid = [...grid];
    const clueObj =
      direction === "across" ? cluesAcross[index] : cluesDown[index];
    const answerLength = newAnswer.length;

    // Find the starting cell for the clue's answer
    updatedGrid.forEach((row, rowIndex) => {
      row.forEach((cell, colIndex) => {
        if (
          (direction === "across" &&
            cell.clueNumber === clueObj.number &&
            cell.clueID === clueObj.id) ||
          (direction === "down" &&
            cell.clueNumber === clueObj.number &&
            cell.clueID === clueObj.id &&
            rowIndex >= rowIndex)
        ) {
          // Clear the cells affected by the removal
          for (let i = 0; i < row.length; i++) {
            const targetRowIndex =
              direction === "down" ? rowIndex : rowIndex + i;
            const targetColIndex =
              direction === "across" ? colIndex : colIndex + i;
            if (
              updatedGrid[targetRowIndex] &&
              updatedGrid[targetRowIndex][targetColIndex] &&
              (direction === "across"
                ? i >= answerLength
                : i >= answerLength && rowIndex + i >= rowIndex)
            ) {
              updatedGrid[targetRowIndex][targetColIndex].cluevalue = "";
            }
          }

          // Update the remaining cells with the adjusted answer
          for (let i = 0; i < answerLength; i++) {
            const targetRowIndex =
              direction === "down" ? rowIndex : rowIndex + i;
            const targetColIndex =
              direction === "across" ? colIndex : colIndex + i;
            if (
              updatedGrid[targetRowIndex] &&
              updatedGrid[targetRowIndex][targetColIndex]
            ) {
              updatedGrid[targetRowIndex][targetColIndex].cluevalue =
                newAnswer[i].toUpperCase();
            }
          }
        }
      });
    });

    // Update the grid state with the modified grid
    setGrid(updatedGrid);
  };

  //---Assessment Data Creation

  const handleCrossWordPuzzleQuestionCreation = () => {
    
    // Remove empty clues (no question or answer) from cluesAcross and cluesDown
    const filteredCluesAcross = cluesAcross.filter(
      (clue) => clue.clue.trim() !== "" && clue.answer.trim() !== ""
    );
    const filteredCluesDown = cluesDown.filter(
      (clue) => clue.clue.trim() !== "" && clue.answer.trim() !== ""
    );

    // Check if each clue has been assigned to the grid
    const missingCluesAcross = filteredCluesAcross.filter((clue) => {
      return !grid.some((row) =>
        row.some((cell) => cell.clueID.includes(clue.id))
      );
    });

    const missingCluesDown = filteredCluesDown.filter((clue) => {
      return !grid.some((row) =>
        row.some((cell) => cell.clueID.includes(clue.id))
      );
    });

    // Validate that the question is provided
    if (questionValue === "") {
      setErrorValue("Question is missing");
      setValidation(true);
      return;
    }

    // Validate that at least one clue is provided
    if (filteredCluesAcross.length === 0 && filteredCluesDown.length === 0) {
      setErrorValue(
        "Please enter at least one clue for either across or down direction."
      );
      setValidation(true);
      return;
    }

    // Check if there are clues not added to the grid
    if (missingCluesAcross.length > 0 || missingCluesDown.length > 0) {
      setErrorValue(
        `There are clues that have not been assigned to the grid. Please add them before proceeding.`
      );
      setValidation(true);
      return;
    }

    const hasClue = grid.some((row) =>
      row.some((cell) => cell.clueNumber !== null || cell.clueID.length > 0)
    );
    if (!hasClue) {
      setErrorValue("Please assign at least one answer to a cell.");
      setValidation(true);
      return;
    }

    // Create assessment data
    const asessmentData = [
      {
        question: questionValue,
        type: selectedAsessmentType,
        options: grid,
        answer: "",
        left: filteredCluesAcross,
        right: filteredCluesDown,
      },
    ];

    console.log(asessmentData);

    // Call the assessment creation function
    assessmentCreate(asessmentData);
  };

  //}

  //rearrange word{
  const handleReArrangeQuestionChange = (html) => {
    setQuestionValue(html);
  };

  //----First Option Set handling

  const handleReArrangeOptionSet = (index, value) => {
    setReArrangeOptionSet((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index] = value;
      return updatedOptions;
    });
  };

  //----Answer set handling

  const handleReArrangeAnswerKey = (index, value) => {
    setReArrangeAnswerValues((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index] = value;
      return updatedOptions;
    });
  };

  //----Options Add

  const handleReArrangeOptionAdd = () => {
    setReArrangeOptionSet((prevOptions) => [...prevOptions, ""]);
  };

  //---Option Deletion

  const handleDeleteReArrangeOption = (index) => {
    const minOptionSetLength = reArrangeOptionSet.length;

    if (minOptionSetLength > 2) {
      setReArrangeOptionSet((prevOptions) =>
        prevOptions.filter((_, i) => i !== index)
      );
    }
  };

  //----Answer Add

  const handleAddReArrangeAnswer = () => {
    if (reArrangeAnswerValues.length < reArrangeOptionSet.length) {
      setReArrangeAnswerValues((prevOptions) => [...prevOptions, ""]);
    } else {
      console.log("Maximum number of answers reached");
    }
  };

  //---Answer Deletion

  const handleDeleteReArrangeAnswer = (index) => {
    const minAnswerLength = reArrangeOptionSet.length;

    if (reArrangeAnswerValues.length > minAnswerLength) {
      setReArrangeAnswerValues((prevOptions) =>
        prevOptions.filter((_, i) => i !== index)
      );
    }
  };

  //clear section

  const handleReArrangeQuestionClear = () => {
    setReArrangeOptionSet(["", "", "", ""]);
    setReArrangeAnswerValues(["", "", "", ""]);
    setQuestionValue("");
    setExplanation("");
  };

  //---Assessment Data Creation
  const handleReArrangeQuestionCreation = () => {
    const answerObject = reArrangeAnswerValues.reduce((acc, value, index) => {
      const trimmedValue = value.trim();
      if (trimmedValue.trim() !== "") {
        acc[index + 1] = trimmedValue; 
      }
      return acc;
    }, {});

    const optionObject = reArrangeOptionSet
    .map((value) => value.trim())
    .filter((value) => value !== "");
    const asessmentData = [
      {
        left: [],
        question: questionValue,
        type: selectedAsessmentType,
        right: [],
        options: optionObject,
        answer: answerObject,
      },
    ];
    console.log(asessmentData);
    if (questionValue.trim() === "") {
      setErrorValue("Question is missing");
      setValidation(true);
    } else if (reArrangeOptionSet.every((option) => option.trim() === "")) {
      setErrorValue(" Option set is missing");
      setValidation(true);
    } else if (reArrangeAnswerValues.every((answer) => answer.trim() === "")) {
      setErrorValue("Answer list is missing");
      setValidation(true);
    } else if (correctMark.trim() === "") {
      setErrorValue("Mark is missing");
      setValidation(true);
    } else if (inCorrectMark.trim() === "") {
      setErrorValue("Incorrect mark is missing");
      setValidation(true);
    } else {
      assessmentCreate(asessmentData);
    }
  };

  // }

  //selection{

  const handleAddDropdownClick = () => {
    const quillEditor = selectTextFieldRef.current.getEditor();
    const range = quillEditor.getSelection();

    if (range) {
      // Insert the dropdown placeholder at the current cursor position
      const dropdownId = dropdowns.length + 1; // Unique id for dropdown
      quillEditor.insertText(range.index, `[dropdown-${dropdownId}]`);

      setDropdowns((prev) => [
        ...prev,
        { id: dropdownId, options: ["", ""] }, // Initialize with two empty options
      ]);
    }
  };

  const handleRemoveDropdownClick = (id) => {
    const quillEditor = selectTextFieldRef.current.getEditor();
    const dropdownIndex = dropdowns.findIndex((dropdown) => dropdown.id === id);

    if (dropdownIndex !== -1) {
      const placeholder = `[dropdown-${id}]`;
      const plainText = quillEditor.getText();
      const dropdownPosition = plainText.indexOf(placeholder);

      if (dropdownPosition !== -1) {
        // Remove the dropdown placeholder from the editor
        quillEditor.deleteText(dropdownPosition, placeholder.length);
        // Update state to remove dropdown
        setDropdowns((prev) => prev.filter((dropdown) => dropdown.id !== id));
        setCorrectOptions((prev) => {
          const { [id]: removedOption, ...rest } = prev; // Remove correct option if exists
          return rest;
        });
      }
    }
  };

  const handleOptionChange = (id, index, value) => {
    setDropdowns((prev) => {
      return prev.map((dropdown) => {
        if (dropdown.id === id) {
          const updatedOptions = [...dropdown.options];
          updatedOptions[index] = value;
          return { ...dropdown, options: updatedOptions };
        }
        return dropdown;
      });
    });
  };

  const handleSelectCorrectOption = (dropdownId, optionIndex) => {
    const selectedValue = dropdowns.find(
      (dropdown) => dropdown.id === dropdownId
    ).options[optionIndex];

    setCorrectOptions((prev) => {
      const key = dropdownId;
      if (prev[key] === selectedValue) {
        const { [key]: removed, ...rest } = prev;
        return rest;
      } else {
        return { ...prev, [key]: selectedValue };
      }
    });
  };

  const handleAddOption = (id) => {
    setDropdowns((prev) =>
      prev.map((dropdown) => {
        if (dropdown.id === id) {
          return { ...dropdown, options: [...dropdown.options, ""] }; // Add empty option
        }
        return dropdown;
      })
    );
  };

  const handleDeleteOption = (dropdownId, index) => {
    setDropdowns((prev) =>
      prev.map((dropdown) => {
        if (dropdown.id === dropdownId) {
          const updatedOptions = dropdown.options.filter((_, i) => i !== index);
          return { ...dropdown, options: updatedOptions };
        }
        return dropdown;
      })
    );

    // Also handle the correct answer if necessary
    if (correctOptions[dropdownId] === index + 1) {
      const { [dropdownId]: removed, ...rest } = correctOptions; // Unset correct option if deleted
      setCorrectOptions(rest);
    }
  };

  //clear section

  const handleSelectQuestionClear = () => {
    setIsDropdownAdded(false);
    setQuestionValue("");
    setExplanation("");
    setDropdowns([]);
    setSelectContent("");
    setCorrectOptions({});
  };

  const handleSelectQuestionCreation = () => {
    const cleanedDropdowns = dropdowns.map((dropdown) => ({
      ...dropdown,
      options: dropdown.options
        .map((option) => option.trim()) 
        .filter((option) => option !== ""), 
    }));
    const trimmedCorrectOptions = Object.fromEntries(
      Object.entries(correctOptions).map(([key, value]) => [key, value.trim()])
    );
    const asessmentData = [
      {
        left: [],
        question: selectContent,
        type: selectedAsessmentType,
        right: [],
        options: cleanedDropdowns,
        answer: trimmedCorrectOptions,
      },
    ];
    console.log(asessmentData);
    if (selectContent.trim() === "") {
      setErrorValue("Question is missing");
      setValidation(true);
    }
    const hasEmptyOptions = dropdowns.some((dropdown) =>
      dropdown.options.some((option) => option.trim() === "")
    );

    if (hasEmptyOptions) {
      setErrorValue("All dropdown options must be filled");
      setValidation(true);
      return;
    }

    // Check if a correct answer is selected for each dropdown
    const hasCorrectAnswers =
      Object.keys(correctOptions).length === dropdowns.length;

    if (!hasCorrectAnswers) {
      setErrorValue("Each dropdown must have a selected correct answer");
      setValidation(true);
      return;
    } else {
      assessmentCreate(asessmentData);
    }
  };


  const renderDropdowns = () => {
    return dropdowns.map((dropdown, dropdownIndex) => (
      <div style={{ margin: ".2rem" }} key={dropdown.id}>
        <h4>Dropdown Options {dropdown.id}</h4>
        {dropdown.options.map((option, optionIndex) => (
          <div
            key={optionIndex}
            style={{ display: "flex", alignItems: "center" }}
          >
            <TextField
              variant="outlined"
              value={option}
              onChange={(e) =>
                handleOptionChange(dropdown.id, optionIndex, e.target.value)
              }
              sx={{ margin: ".3rem 0", marginRight: "1rem", flex: 1 }}
            />
            <Checkbox
              checked={correctOptions[dropdown.id] === option}
              onChange={() =>
                handleSelectCorrectOption(dropdown.id, optionIndex)
              }
              color="primary"
            />
            <IconButton
              aria-label="delete"
              color="error"
              onClick={() => handleDeleteOption(dropdown.id, optionIndex)}
              disabled={dropdown.options.length <= 2} // Disable if only two options left
            >
              <MdDelete />
            </IconButton>
          </div>
        ))}
        <Button
          variant="contained"
          sx={{ marginRight: "1rem" }}
          onClick={() => handleAddOption(dropdown.id)}
        >
          Add Option
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={() => handleRemoveDropdownClick(dropdown.id)}
        >
          Remove Dropdown
        </Button>
      </div>
    ));
  };

  //}
  //{highlight
  const handleHighlightQuestionChange = (html) => {
    setQuestionValue(html);
  };
 
  const handleSelectionChange = (range, oldRange, source) => {
    const quill = quillRef.current.getEditor();
    
    if (range && range.length > 0) {
      const selectedText = quill.getText(range.index, range.length).trim();
      if (
        previousRange &&
        previousRange.index === range.index &&
        previousRange.length === range.length
      ) {
        quill.formatText(range.index, range.length, 'color', false);
        setPreviousRange(null); 
        setLastSelectedText(null); 
      } else {
        if (previousRange) {
          quill.formatText(previousRange.index, previousRange.length, 'color', false);
        }
        
        quill.formatText(range.index, range.length, 'color', '#FF7A00'); 
        setPreviousRange(range); 
        setLastSelectedText(selectedText);
      }
      setHighlightContent(quill.root.innerHTML);
    }
  };


  const [previousRange, setPreviousRange] = useState(null);
  

  const handleHighlightQuestionClear = () => {
    setHighlightContent("");
    setLastSelectedText("");
    setQuestionValue("");
    setExplanation("");
  };

  const handleHighlightQuestionCreation = () => {
    const asessmentData = [
      {
        left: [],
        question: questionValue,
        type: selectedAsessmentType,
        right: [],
        options: highlightContent,
        answer: lastSelectedText,
      },
    ];
    console.log(asessmentData);
    if (questionValue.trim() === "") {
      setErrorValue("Question is missing");
      setValidation(true);
    } else if (highlightContent.trim() === "") {
      setErrorValue("Option is missing");
      setValidation(true);
    } else if (lastSelectedText.trim() === "") {
      setErrorValue("Answer  is missing");
      setValidation(true);
    } else if (correctMark.trim() === "") {
      setErrorValue("Mark is missing");
      setValidation(true);
    } else if (inCorrectMark.trim() === "") {
      setErrorValue("Incorrect mark is missing");
      setValidation(true);
    } else {
      assessmentCreate(asessmentData);
    }
  };

  //}

  //{rewrite
  const handleReWriteQuestionChange = (html) => {
    setQuestionValue(html);
  };

  //clear section

  const handleReWriteQuestionClear = () => {
    setQuestionValue("");
    setReWriteAnswer("");
    setExplanation("");
  };

  //---Assessment Data Creation

  const handleReWriteQuestionCreation = () => {
    const trimmedAnswer = reWriteAnswer.trim();
    const asessmentData = [
      {
        question: questionValue,
        type: selectedAsessmentType,
        options: "",
        answer: trimmedAnswer,
      },
    ];
    console.log(asessmentData);
    if (questionValue.trim() === "") {
      setErrorValue("Question is missing");
      setValidation(true);
    } else if (reWriteAnswer.trim() === "") {
      setErrorValue("Answer is missing");
      setValidation(true);
    } else if (correctMark.trim() === "") {
      setErrorValue("Mark is missing");
      setValidation(true);
    } else if (inCorrectMark.trim() === "") {
      setErrorValue("Incorrect mark is missing");
      setValidation(true);
    } else {
      assessmentCreate(asessmentData);
    }
  };

  //}

  async function adminAsessmentType() {
    await fetch(`${baseUrl}/admin/assessment/dropdown`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setAsessmentTypevalues(data.data);
      });
  }

  useEffect(() => {
    // Set initial state for Admin
    adminAsessmentType();
    setAsessmentTypeView(true);
    setNoSelection(false);
    setAiGeneration(false);
  }, []);

  async function assessmentCreate(data) {
    setIsDisabled(true)
    const topic_id_main = localStorage.getItem("SelectedTopicID");
    const topic_id_selected = localStorage.getItem("SelectedsubTopicID");
    const topic_name_selected = localStorage.getItem("SelectedsubTopicName");
    await fetch(`${baseUrl}/admin/assessment/generation/self`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: orgId,
        topic_id: topic_id_selected,
        topic_name: topic_name_selected,
        main_topic_id: topic_id_main,
        assessment: data,
        admin_id: adminId,
        comment_by_other: isCheckedOther,
        comment_by_mentor: isCheckedMentor,
        comment_by_learner: isCheckedLearner,
        mark: correctMark,
        negative_mark: inCorrectMark,
        publish_status: assessmentStatus,
        question_level:assessmentLevel,
        question_explained: explanation,
        
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          handleSelectQuestionClear();
          setQuestionCreationSuccess(true);
          setIsCheckedLearner(false);
          setIsCheckedMentor(false);
          setIsCheckedOther(false);
          handlematchImageQuestionClear();
          setCorrectMark("1");
          setInCorrectMark("0");
          setOptions(["", "", "", ""]);
          setQuestionValue("");
          setCorrectAnswers([]);
          setOrdinaryAnswer("");
          setFirstOptionSet(["", "", "", ""]);
          setSecondOptionSet(["", "", "", ""]);
          setContent("");
          setBlankAnswers({});
          setMatchAnswerValues(["", "", "", ""]);
          setRecordings([
            {
              audio: null,
              mediaRecorder: null,
              isRecording: false,
              answer: "",
            },
          ]);
          setGrid(
            Array.from({ length: 10 }, () =>
              Array.from({ length: 10 }, () => ({
                cluevalue: "",
                clueNumber: null,
                clueID: [],
              }))
            )
          );
          setCluesAcross([
            { id: "1027277301", number: 1, clue: "", answer: "" },
          ]);
          setCluesDown([{ id: "1037101023", number: 1, clue: "", answer: "" }]);
          setExplanation("");
          setSelectContent("");
          setCorrectOption(null);
          setQuestionValue("");
          setReArrangeAnswerValues(["", "", "", ""]);
          setReArrangeOptionSet(["", "", "", ""]);
          setDropdownOptions([]);
          setIsDropdownAdded(false);
          setHighlightContent("");
          setReWriteAnswer("");
          setLastSelectedText("");
        }
        setIsDisabled(false)
      });
  }

  async function aiQuestionGeneration() {
    const topic_id_main = localStorage.getItem("SelectedTopicID");
    const topic_id_selected = localStorage.getItem("SelectedsubTopicID");
    const topic_name_selected = localStorage.getItem("SelectedsubTopicName");
    await fetch(`${baseUrl}/admin/assessment/generation/ai`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: orgId,
        topic_id: topic_id_selected,
        topic_name: topic_name_selected,
        main_topic_id: topic_id_main,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setAiGenerationStatus(true);
          setQuestionCreationSuccess(true);
        }
      });
  }

  async function filesGet() {
    await fetch(`${baseUrl}/gallery/file/get`, {
      method: "POST",
      body: JSON.stringify({
        org_id: orgId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setMediaCollection(data.data);
      });
  }

  useEffect(() => {
    if (closing) {
      setTimeout(() => {
        setClosing(false);
      }, 1000);
    }
  }, [closing]);

  return (
    <div className={style.Container}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={questionCreationSuccess}
        onClose={() => {
          setQuestionCreationSuccess(false);
        }}
      >
        <Alert
          severity="success"
          variant="outlined"
          sx={{
            width: "100%",
            fontSize: "1.15rem",
            border: "2px solid green",
            borderRadius: "6px",
            background: "#fff",
          }}
        >
          <div className={style.alertSection}>
            {aiGenerationStatus ? (
              <p>Ai is generating your assessments!</p>
            ) : (
              <p>Question created successfully!</p>
            )}

            <div className={style.confirmationSection}>
              {aiGenerationStatus ? (
                ""
              ) : (
                <Button
                  sx={{
                    margin: "0 0.25rem",
                    background: "#f2f4faad",
                    border: "2px solid #b2a5d3",
                    color: "#20126d",
                  }}
                  onClick={() => {
                    setQuestionCreationSuccess(false);
                  }}
                >
                  Continue
                </Button>
              )}

              <Button
                sx={{
                  margin: "0 0.25rem",
                  background: "#f9f7f8",
                  border: "2px solid #f9a1d6",
                  color: "#af5688",
                }}
                onClick={() => {
                  setQuestionCreationSuccess(false);

                  navigate("/asessments", {
                    state: {
                      organization: orgId,
                      mainTopic: mainTopicId,
                      topicName: topicName,
                      topicId: topicId,
                      subcategories: state.subCategories,
                      mainCategory: state.mainCategory,
                    },
                  });
                }}
              >
                View
              </Button>
            </div>
          </div>
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={validation}
        sx={{ width: "25rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setValidation(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.1rem" }}
        >
          {errorValue}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={settingsValidation}
        sx={{ width: "25rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setSettingsValidation(false);
        }}
      >
        <Alert
          variant="filled"
          severity="info"
          sx={{ width: "100%", fontSize: "1.1rem" }}
        >
          {questionValue === ""
            ? "Settings added please save the question"
            : "Please save question"}
        </Alert>
      </Snackbar>
      <div className={style.Header}>
        <div className={style.headerText}>
          <h5>Create your Assessments Here!</h5>
        </div>
      </div>
      <div className={style.asessmentCreationSection}>
        <div className={style.selectionSection}>
          {/* <div className={style.generationContainer}>
            <div className={style.generationValue}>
              <Radio
                color="success"
                name="generation-type-radio"
                checked={generationTypeValue === "Admin"}
                onChange={() => {
                  handleGenerationTypeChange("Admin");
                }}
              />
              <p>Manual</p>
            </div>
            <div className={style.generationValue}>
              <Radio
                color="success"
                name="generation-type-radio"
                checked={generationTypeValue === "Ai"}
                onChange={() => {
                  handleGenerationTypeChange("Ai");
                }}
              />
              <p>Generated by AI</p>
            </div>
          </div> */}

          {/* Assessment type listing */}

          {asessmentTypeView ? (
            <div className={style.asessmentTypes}>
              <FormControl sx={{ width: "50%" }}>
                <InputLabel id="demo-simple-select-label">
                  Choose your assessment type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Choose your assessment type"
                  value={selectedAsessmentType}
                  onChange={handleAsessmentTypeChange}
                >
                  {asessmentTypeValues &&
                    asessmentTypeValues.length > 0 &&
                    asessmentTypeValues.map((type, index) => {
                      // Replace "ordinary" with "Descriptive"
                      const displayedType =
                        type === "ordinary"
                          ? "Descriptive"
                          : type.charAt(0).toUpperCase() + type.slice(1);

                      return (
                        <MenuItem key={index} value={type}>
                          {displayedType}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className={style.questionSection}>
          {noSelection ? (
            <div className={style.noSelection}>
              <Alert severity="info">
                To create an assessment choose a generation type
              </Alert>
            </div>
          ) : (
            ""
          )}

          {/* Ai Generation section */}
          {aiGeneration ? (
            <div className={style.aiGenerationSection}>
              <div className={style.aiButton}>
                <button
                  onClick={() => {
                    aiQuestionGeneration();
                  }}
                >
                  Ai Generated Question Creation
                </button>
              </div>
            </div>
          ) : (
            ""
          )}

          {/* fill in the blank Question */}

          {fillTheBlankView ? (
            <div className={style.fillQuestionSection}>
              <div className={style.questionCreation}>
                <div className={style.creationTop}>
                  <div className={style.creationTopLeft}>
                    <TbMessageQuestion />
                    <p>Enter your question?</p>
                    <span>*</span>
                  </div>
                  <div className={style.actionButtons}>
                    <div className={style.settingsIcon}>
                      <IconButton>
                        <IoSettingsOutline
                          onClick={() => {
                            setSettingView(true);
                          }}
                        />
                      </IconButton>
                    </div>
                    <div className={style.creationTopRight}>
                      <Button
                        variant="contained"
                        endIcon={<MdAdd />}
                        color="secondary"
                        onClick={handleAddBlankClick}
                      >
                        Add Blank
                      </Button>
                    </div>
                    <div className={style.questionClearButton}>
                      <button onClick={handleBlankQuestionClear}>Clear</button>
                    </div>
                    <div className={style.questionCreateButton}>
                      <button disabled={isDisabled} onClick={handleFilltheBlankQuestionCreation}>
                        Save Question
                      </button>
                    </div>
                  </div>
                </div>

                <div className={style.creationBottomBlank}>
                  <div className={style.textArea}>
                    <ReactQuill
                      modules={modules}
                      formats={formats}
                      theme="snow"
                      ref={quillRef}
                      value={content}
                      onChange={(value) => setContent(value)}
                      style={{ width: "100%", height: "300px" }}
                    />
                  </div>
                </div>
              </div>
              <div className={style.optionSet}>
                <div className={style.optionTop}>
                  <MdOutlineQuestionAnswer />
                  <p>Enter your blank values</p>
                  <p>(To remove the blanks use the delete button)</p>
                  <span>*</span>
                </div>
                <div className={style.optionBottomLeft}>
                  {Object.keys(blankAnswers).map((blankNumber) => (
                    <div key={blankNumber} className={style.blankValues}>
                      <p>Blank {blankNumber}:</p>
                      <TextField
                        sx={{ width: "85%", margin: "0 1rem" }}
                        variant="outlined"
                        value={blankAnswers[blankNumber]}
                        onChange={(e) =>
                          handleBlankAnswerChange(blankNumber, e.target.value)
                        }
                      />
                      <IconButton
                        aria-label="delete"
                        color="error"
                        onClick={() => handleDeleteBlank(blankNumber)}
                      >
                        <MdDelete />
                      </IconButton>
                    </div>
                  ))}
                </div>
              </div>
              <div className={style.explanation}>
                <h4>Explanation</h4>
                <div className={style.explanationTextArea}>
                  <ReactQuill
                    modules={modules}
                    formats={formats}
                    theme="snow"
                    value={explanation}
                    onChange={handleExplanationChange}
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {/* Multiple Choice QUestion */}

          {multipleChoiceView ? (
            <div className={style.fillQuestionSection}>
              <div className={style.questionCreation}>
                <div className={style.creationTop}>
                  <div className={style.creationTopLeft}>
                    <TbMessageQuestion />
                    <p>Enter your question?</p>
                    <span>*</span>
                  </div>
                  <div className={style.actionButtons}>
                    <div className={style.settingsIcon}>
                      <IconButton>
                        <IoSettingsOutline
                          onClick={() => {
                            setSettingView(true);
                          }}
                        />
                      </IconButton>
                    </div>
                    <div className={style.questionClearButton}>
                      <button onClick={handleMultipleQuestionClear}>
                        Clear
                      </button>
                    </div>
                    <div className={style.questionCreateButton}>
                      <button disabled={isDisabled} onClick={handleMulitipleQuestionCreation}>
                        Save Question
                      </button>
                    </div>
                  </div>
                </div>
                <div className={style.creationBottom}>
                  <ReactQuill
                    modules={modules}
                    formats={formats}
                    theme="snow"
                    value={questionValue}
                    onChange={handleMultipleQuestionChange}
                    style={{ width: "100%", height: "300px" }}
                  />
                  {/* <TextField
                    sx={{ width: "100%" }}
                    id="outlined-basic"
                    multiline
                    rows={6}
                    variant="outlined"
                    onChange={(e) => {
                      setQuestionValue(e.target.value);
                    }}
                    value={questionValue}
                  /> */}
                </div>
              </div>

              <div className={style.optionSet}>
                <div className={style.optionTop}>
                  <MdOutlineQuestionAnswer />
                  <p>Enter your options</p>
                  <p>(Please check the box against the correct answer)</p>
                  <span>*</span>
                </div>
                <div className={style.optionBottom}>
                  <div className={style.optionBottomLeft}>
                    {options.map((option, index) => (
                      <div key={index} className={style.optionItem}>
                        <TextField
                          sx={{ width: "95%" }}
                          required
                          variant="filled"
                          value={option}
                          onChange={(e) =>
                            handleMultipleAnsweChange(index, e.target.value)
                          }
                        />
                        <Checkbox
                          // disabled={option === ""}
                          checked={correctAnswers.includes(index)}
                          onChange={() => handleCheckboxChange(index)}
                          disabled={options[index] === ""}
                        />
                        <IconButton
                          aria-label="delete"
                          color="error"
                          onClick={() => handleMultipleOptionDelete(index)}
                          disabled={deleteDisable}
                        >
                          <MdDelete />
                        </IconButton>
                      </div>
                    ))}
                  </div>
                  <div className={style.firstOptionAdd}>
                    <Button
                      variant="contained"
                      endIcon={<MdAddCircleOutline />}
                      color="secondary"
                      onClick={handleMultipleOptionAdd}
                    >
                      Add Options
                    </Button>
                  </div>
                </div>
              </div>
              <div className={style.explanation}>
                <h4>Explanation</h4>
                <div className={style.explanationTextArea}>
                  <ReactQuill
                    modules={modules}
                    formats={formats}
                    theme="snow"
                    value={explanation}
                    onChange={handleExplanationChange}
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {/* Ordinay Questions */}

          {ordinary ? (
            <div className={style.fillQuestionSection}>
              <div className={style.questionCreation}>
                <div className={style.creationTop}>
                  <div className={style.creationTopLeft}>
                    <TbMessageQuestion />
                    <p>Enter your question?</p>
                    <span>*</span>
                  </div>
                  <div className={style.actionButtons}>
                    <div className={style.settingsIcon}>
                      <IconButton>
                        <IoSettingsOutline
                          onClick={() => {
                            setSettingView(true);
                          }}
                        />
                      </IconButton>
                    </div>
                    <div className={style.questionClearButton}>
                      <button onClick={handleOrdinaryQuestionClear}>
                        Clear
                      </button>
                    </div>
                    <div className={style.questionCreateButton}>
                      <button disabled={isDisabled} onClick={handleOrdinaryQuestionCreation}>
                        Save Question
                      </button>
                    </div>
                  </div>
                </div>
                <div className={style.creationBottom}>
                  <ReactQuill
                    theme="snow"
                    value={questionValue}
                    modules={modules}
                    formats={formats}
                    onChange={handleOrdinaryQuestionChange}
                    style={{ width: "100%", height: "300px" }}
                  />
                </div>
              </div>
              <div className={style.optionSet}>
                <div className={style.optionTop}>
                  <MdOutlineQuestionAnswer />
                  <p>Enter your answer</p>
                  <span>*</span>
                </div>
                <div className={style.optionBottomLeft}>
                  <TextField
                    fullWidth
                    multiline
                    rows={8}
                    variant="outlined"
                    value={ordinaryAnswer}
                    onChange={handleOrdinaryAnswerChange}
                  />
                </div>
              </div>
              <div className={style.explanation}>
                <h4>Explanation</h4>
                <div className={style.explanationTextArea}>
                  <ReactQuill
                    modules={modules}
                    formats={formats}
                    theme="snow"
                    value={explanation}
                    onChange={handleExplanationChange}
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {/* Match The Following Questions */}

          {matchValueView ? (
            <div className={style.fillQuestionSection}>
              <div className={style.questionCreation}>
                <div className={style.creationTop}>
                  <div className={style.creationTopLeft}>
                    <TbMessageQuestion />
                    <p>Enter your question?</p>
                    <span>*</span>
                  </div>
                  <div className={style.actionButtons}>
                    <div className={style.settingsIcon}>
                      <IconButton>
                        <IoSettingsOutline
                          onClick={() => {
                            setSettingView(true);
                          }}
                        />
                      </IconButton>
                    </div>
                    <div className={style.questionClearButton}>
                      <button onClick={handlematchQuestionClear}>Clear</button>
                    </div>
                    <div className={style.questionCreateButton}>
                      <button disabled={isDisabled} onClick={handleMatchQuestionCreation}>
                        Save Question
                      </button>
                    </div>
                  </div>
                </div>
                <div className={style.creationBottom}>
                  <ReactQuill
                    modules={modules}
                    formats={formats}
                    theme="snow"
                    value={questionValue}
                    onChange={handleMatchQuestionChange}
                    style={{ width: "100%", height: "300px" }}
                  />
                  {/* <TextField
                    sx={{ width: "100%" }}
                    id="outlined-basic"
                    multiline
                    rows={5}
                    variant="outlined"
                    onChange={(e) => {
                      setQuestionValue(e.target.value);
                    }}
                    value={questionValue}
                  /> */}
                </div>
              </div>
              <div className={style.mainOptionSet}>
                <div className={style.firstOptionSet}>
                  <div className={style.firstOptionTop}>
                    <MdOutlineQuestionAnswer />
                    <p>Enter your first option set</p>
                  </div>
                  {firstOptionSet.map((first, index) => (
                    <div className={style.firstOptionSetValues} key={index}>
                      <TextField
                        sx={{ width: "95%" }}
                        required
                        variant="filled"
                        value={first}
                        onChange={(e) =>
                          handleFirstOptionSet(index, e.target.value)
                        }
                      />
                      <IconButton
                        aria-label="delete"
                        color="error"
                        onClick={() => handleDeleteMatchOption(index, "first")}
                        disabled={firstOptionSet.length <= 2}
                      >
                        <MdDelete />
                      </IconButton>
                    </div>
                  ))}
                  <div className={style.firstOptionAdd}>
                    <Button
                      variant="contained"
                      endIcon={<MdAddCircleOutline />}
                      color="secondary"
                      onClick={() => handleMatchOptionAdd("first")}
                    >
                      Add first Options
                    </Button>
                  </div>
                </div>
                <div className={style.secondOptionSet}>
                  <div className={style.secondOptionTop}>
                    <MdOutlineQuestionAnswer />
                    <p>Enter your second option set</p>
                  </div>
                  {secondOptionSet.map((second, index) => (
                    <div className={style.secondOptionSetValues} key={index}>
                      <TextField
                        sx={{ width: "95%" }}
                        required
                        variant="filled"
                        value={second}
                        onChange={(e) =>
                          handleSecondOptionSet(index, e.target.value)
                        }
                      />
                      <IconButton
                        aria-label="delete"
                        color="error"
                        onClick={() => handleDeleteMatchOption(index, "second")}
                        disabled={secondOptionSet.length <= 2}
                      >
                        <MdDelete />
                      </IconButton>
                    </div>
                  ))}
                  <div className={style.secondOptionAdd}>
                    <Button
                      variant="contained"
                      endIcon={<MdAddCircleOutline />}
                      color="secondary"
                      onClick={() => handleMatchOptionAdd("second")}
                    >
                      Add second Options
                    </Button>
                  </div>
                </div>
                <div className={style.matchAnswerKey}>
                  <div className={style.matchAnswerTop}>
                    <MdOutlineQuestionAnswer />
                    <p>Enter your answer Keys</p>
                  </div>
                  {matchAnswerValues.map((answer, index) => (
                    <div className={style.matchAnswerValues} key={index}>
                      <TextField
                        sx={{ width: "95%" }}
                        required
                        variant="filled"
                        value={answer}
                        onChange={(e) =>
                          handleMatchAnswerKey(index, e.target.value)
                        }
                      />
                      <IconButton
                        aria-label="delete"
                        color="error"
                        onClick={() => handleDeleteMatchAnswer(index)}
                        disabled={matchAnswerValues.length <= 2}
                      >
                        <MdDelete />
                      </IconButton>
                    </div>
                  ))}
                  <div className={style.answerKeyAdd}>
                    <Button
                      variant="contained"
                      endIcon={<MdAddCircleOutline />}
                      color="secondary"
                      onClick={handleAddMatchAnswer}
                    >
                      Add Answer
                    </Button>
                  </div>
                </div>
              </div>
              <div className={style.explanation}>
                <h4>Explanation</h4>
                <div className={style.explanationTextArea}>
                  <ReactQuill
                    modules={modules}
                    formats={formats}
                    theme="snow"
                    value={explanation}
                    onChange={handleExplanationChange}
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {/*dictaion question*/}
          {dictationView ? (
            <div className={style.fillQuestionSection}>
              <div className={style.questionCreation}>
                <div className={style.creationTop}>
                  <div className={style.creationTopLeft}>
                    <TbMessageQuestion />
                    <p>Enter your question?</p>
                    <span>*</span>
                  </div>
                  <div className={style.actionButtons}>
                    <div className={style.settingsIcon}>
                      <IconButton>
                        <IoSettingsOutline
                          onClick={() => {
                            setSettingView(true);
                          }}
                        />
                      </IconButton>
                    </div>
                    <div className={style.questionClearButton}>
                      <button onClick={handleDictationQuestionClear}>
                        Clear
                      </button>
                    </div>
                    <div className={style.questionCreateButton}>
                      <button disabled={isDisabled} onClick={handleDictationQuestionCreation}>
                        Save Question
                      </button>
                    </div>
                  </div>
                </div>
                <div className={style.creationBottom}>
                  <ReactQuill
                    modules={modules}
                    formats={formats}
                    theme="snow"
                    value={questionValue}
                    onChange={handleDictaionQuestionChange}
                    style={{ width: "100%", height: "300px" }}
                  />
                </div>
              </div>

              <div className={style.optionSet}>
                <div className={style.optionTop}>
                  <MdOutlineQuestionAnswer />
                  <p>Enter your Question</p>
                  <p>(Please enter answer against the audio)</p>
                  <span>*</span>
                </div>
                <div className={style.optionBottom}>
                  <div className={style.optionBottomDictation}>
                    {recordings.map((recording, index) => (
                      <>
                        <div key={index} className={style.MicSection}>
                          {/* Start Recording button */}
                          <Button
                            startIcon={<MdOutlineMic />}
                            variant="contained"
                            color="secondary"
                            onClick={() => handleStartStopRecording(index)}
                          >
                            {recording.isRecording
                              ? "Stop Recording"
                              : "Start Recording"}
                          </Button>

                          <TextField
                            sx={{
                              width: "50%",
                              border: "none",
                              margin: "0 4px",
                            }}
                            label="Enter Answer"
                            value={recording.answer}
                            onChange={(e) =>
                              handleAnswerChange(index, e.target.value)
                            }
                          />
                          {recording.audio && (
                            <audio controls>
                              <source src={recording.audio} type="audio/wav" />
                              Your browser does not support the audio element.
                            </audio>
                          )}
                          <Button
                            disabled={recordings.length <= 1}
                            sx={{ margin: "0 4px", fontSize: "1.25rem" }}
                            variant="contained"
                            color="secondary"
                            onClick={() => handleDeleteRecording(index)}
                          >
                            <MdDelete />
                          </Button>
                        </div>
                      </>
                    ))}
                  </div>
                  <div className={style.firstOptionAdd}>
                    <Button
                      variant="contained"
                      endIcon={<MdAddCircleOutline />}
                      color="secondary"
                      onClick={handleAddRecording}
                    >
                      Add Options
                    </Button>
                  </div>
                </div>
              </div>
              <div className={style.explanation}>
                <h4>Explanation</h4>
                <div className={style.explanationTextArea}>
                  <ReactQuill
                    modules={modules}
                    formats={formats}
                    theme="snow"
                    value={explanation}
                    onChange={handleExplanationChange}
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* Match The Following Questions with image */}

          {matchOptionImageView ? (
            <div className={style.fillQuestionSection}>
              <div className={style.questionCreation}>
                <div className={style.creationTop}>
                  <div className={style.creationTopLeft}>
                    <TbMessageQuestion />
                    <p>Enter your question?</p>
                    <span>*</span>
                  </div>
                  <div className={style.actionButtons}>
                    <div className={style.settingsIcon}>
                      <IconButton>
                        <IoSettingsOutline
                          onClick={() => {
                            setSettingView(true);
                          }}
                        />
                      </IconButton>
                    </div>
                    <div className={style.questionClearButton}>
                      <button onClick={handlematchImageQuestionClear}>
                        Clear
                      </button>
                    </div>
                    <div className={style.questionCreateButton}>
                      <button disabled={isDisabled} onClick={handleImageMatchQuestionCreation}>
                        Save Question
                      </button>
                    </div>
                  </div>
                </div>
                <div className={style.creationBottom}>
                  <ReactQuill
                    modules={modules}
                    formats={formats}
                    theme="snow"
                    value={questionValue}
                    onChange={handleImageMatchQuestionChange}
                    style={{ width: "100%", height: "300px" }}
                  />
                  {/* <TextField
                    sx={{ width: "100%" }}
                    id="outlined-basic"
                    multiline
                    rows={5}
                    variant="outlined"
                    onChange={(e) => {
                      setQuestionValue(e.target.value);
                    }}
                    value={questionValue}
                  /> */}
                </div>
              </div>
              <div className={style.mainOptionSet}>
                <div className={style.firstOptionSet}>
                  <div className={style.firstOptionTop}>
                    <MdOutlineQuestionAnswer />
                    <p>Enter your first option image set</p>
                  </div>
                  {firstOptionSetImage.map((first, index) => (
                    <div className={style.firstOptionSetValues} key={index}>
                      {first ? (
                        <div onClick={() => handleReplaceImage(index)}>
                          <img
                            src={first}
                            alt={`Option ${index + 1}`}
                            style={{ width: "100px", height: "100px" }}
                          />
                          <input
                            id={`fileInput_${index}`}
                            type="file"
                            accept="image/*"
                            style={{ display: "none" }} // Hide the input element
                            onChange={(e) =>
                              handleImageFirstOptionSet(
                                index,
                                e.target.files[0]
                              )
                            }
                          />
                        </div>
                      ) : (
                        <div className={style.ImageInput}>
                          <>
                            <TextField
                              onClick={() => handleReplaceImage(index)} // Trigger the file input when the text field is clicked
                              placeholder="Choose file"
                              sx={{ width: "95%" }}
                              variant="filled"
                              InputProps={{
                                readOnly: true, // Make the text field read-only to prevent manual editing
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      edge="end"
                                      onClick={() => handleReplaceImage(index)}
                                    >
                                      <IoMdCloudUpload />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <input
                              type="file"
                              id={`fileInput_${index}`}
                              accept="image/*"
                              style={{ display: "none" }}
                              onChange={(e) =>
                                handleImageFirstOptionSet(
                                  index,
                                  e.target.files[0]
                                )
                              }
                            />
                          </>
                        </div>
                      )}
                      <IconButton
                        aria-label="delete"
                        color="error"
                        onClick={() =>
                          handleDeleteImageMatchOption(index, "first")
                        }
                        disabled={firstOptionSetImage.length <= 2}
                      >
                        <MdDelete />
                      </IconButton>
                    </div>
                  ))}
                  <div className={style.firstOptionAdd}>
                    <Button
                      variant="contained"
                      endIcon={<MdAddCircleOutline />}
                      color="secondary"
                      onClick={() => handleImageMatchOptionAdd("first")}
                    >
                      Add first Options
                    </Button>
                  </div>
                </div>
                <div className={style.secondOptionSet}>
                  <div className={style.secondOptionTop}>
                    <MdOutlineQuestionAnswer />
                    <p>Enter your second option set</p>
                  </div>
                  {secondOptionSetImageText.map((second, index) => (
                    <div className={style.secondOptionSetValues} key={index}>
                      <TextField
                        sx={{ width: "95%" }}
                        required
                        variant="filled"
                        value={second}
                        onChange={(e) =>
                          handleImageSecondOptionSet(index, e.target.value)
                        }
                      />
                      <IconButton
                        aria-label="delete"
                        color="error"
                        onClick={() =>
                          handleDeleteImageMatchOption(index, "second")
                        }
                        disabled={secondOptionSetImageText.length <= 2}
                      >
                        <MdDelete />
                      </IconButton>
                    </div>
                  ))}
                  <div className={style.secondOptionAdd}>
                    <Button
                      variant="contained"
                      endIcon={<MdAddCircleOutline />}
                      color="secondary"
                      onClick={() => handleImageMatchOptionAdd("second")}
                    >
                      Add second Options
                    </Button>
                  </div>
                </div>
                <div className={style.matchAnswerKey}>
                  <div className={style.matchAnswerTop}>
                    <MdOutlineQuestionAnswer />
                    <p>Enter your answer Keys</p>
                  </div>
                  {imageMatchAnswerValues.map((answer, index) => (
                    <div className={style.matchAnswerValues} key={index}>
                      <TextField
                        sx={{ width: "95%" }}
                        required
                        variant="filled"
                        value={answer}
                        onChange={(e) =>
                          handleImageMatchAnswerKey(index, e.target.value)
                        }
                      />
                      <IconButton
                        aria-label="delete"
                        color="error"
                        onClick={() => handleDeleteImageMatchAnswer(index)}
                        disabled={imageMatchAnswerValues.length <= 2}
                      >
                        <MdDelete />
                      </IconButton>
                    </div>
                  ))}
                  <div className={style.answerKeyAdd}>
                    <Button
                      variant="contained"
                      endIcon={<MdAddCircleOutline />}
                      color="secondary"
                      onClick={handleAddImageMatchAnswer}
                    >
                      Add Answer
                    </Button>
                  </div>
                </div>
              </div>
              <div className={style.explanation}>
                <h4>Explanation</h4>
                <div className={style.explanationTextArea}>
                  <ReactQuill
                    modules={modules}
                    formats={formats}
                    theme="snow"
                    value={explanation}
                    onChange={handleExplanationChange}
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {crossWordPuzzle ? (
            <div className={style.fillQuestionSection}>
              <div className={style.questionCreation}>
                <div className={style.creationTop}>
                  <div className={style.creationTopLeft}>
                    <TbMessageQuestion />
                    <p>Enter your question?</p>
                    <span>*</span>
                  </div>
                  <div className={style.actionButtons}>
                    <div className={style.settingsIcon}>
                      <IconButton>
                        <IoSettingsOutline
                          onClick={() => {
                            setSettingView(true);
                          }}
                        />
                      </IconButton>
                    </div>
                    <div className={style.questionClearButton}>
                      <button onClick={handleCrossWordPuzzleQuestionClear}>
                        Clear
                      </button>
                    </div>
                    <div className={style.questionCreateButton} >
                      <button disabled={isDisabled} onClick={handleCrossWordPuzzleQuestionCreation}>
                        Save Question
                      </button>
                    </div>
                  </div>
                </div>
                <div className={style.creationBottom}>
                  <ReactQuill
                    modules={modules}
                    formats={formats}
                    theme="snow"
                    value={questionValue}
                    onChange={handleCrossWordPuzzleQuestionChange}
                    style={{ width: "100%", height: "300px" }}
                  />
                </div>
              </div>

              <div className={style.optionSet}>
                <div className={style.optionTop}>
                  <MdOutlineQuestionAnswer />
                  <p>
                    Enter your clues and answer and click the button Select
                    Starting Cell to add in the cells{" "}
                  </p>
                  <span>*</span>
                </div>
                <div className={style.optionBottom}>
                  <div className={style.crosswordcontainer}>
                    <div className={style.crosswordpuzzle}>
                      {grid.map((row, rowIndex) => (
                        <div key={rowIndex} className={style.row}>
                          {row.map((cell, colIndex) => (
                            <div
                              key={`${rowIndex}-${colIndex}`}
                              className={style.cellContainer}
                            >
                              <input
                                type="text"
                                maxLength="1"
                                className={style.cell}
                                value={cell.cluevalue}
                                readOnly
                                onClick={() =>
                                  handleCellClick(rowIndex, colIndex)
                                }
                                // onChange={(e) => handleCrossWordPuzzleinputChange(e, rowIndex, colIndex)}
                              />
                              {cell.clueNumber && ( // Display clue number only if it exists
                                <div className={style.clueNumber}>
                                  {cell.clueNumber}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                    <div className={style.PuzzleQuestion}>
                      <div className={style.QuestionAcrossBox}>
                        <div className={style.QuestionAcross}>
                          <h4>Across</h4>
                          {cluesAcross.map((clueObj, index) => (
                            <div
                              key={index}
                              className={style.QuestionAcrossInputContainer}
                            >
                              <span>{clueObj.number}. </span>
                              <textarea
                                rows={2} 
                                cols={30}
                                placeholder="Enter your clue"
                                value={clueObj.clue}
                                onChange={(e) => handleClueChange(e, index, "across")}
                                className={
                                  style.QuestionContainer
                                }
                              />
                              <input
                                type="text"
                                placeholder="Enter your answer"
                                value={clueObj.answer}
                                onChange={(e) =>
                                  handleClueAnswerChange(e, index, "across")
                                }
                              />

                              <div className={style.crossAction}>
                                <Button
                                  sx={{ width: "fit-content", margin: "4px 0" }}
                                  variant="contained"
                                  onClick={() =>
                                    startSelectingCell(
                                      index,
                                      "across",
                                      clueObj.id
                                    )
                                  }
                                >
                                  Select Starting Cell
                                </Button>
                                <div className={style.delBtn}>
                                  <MdDelete
                                    className={style.delBtnIco}
                                    onClick={() =>
                                      removeClue("across", clueObj.id)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                          <Button
                            sx={{ width: "fit-content" }}
                            variant="contained"
                            endIcon={<MdAddCircleOutline />}
                            color="secondary"
                            onClick={() => addClue("across")}
                          >
                            Add Across Clue
                          </Button>
                        </div>
                      </div>
                      <div className={style.QuestionDownBox}>
                        <div className={style.QuestionDown}>
                          <h4>Down</h4>
                          {cluesDown.map((clueObj, index) => (
                            <div
                              key={index}
                              className={style.QuestionDownInputContainer}
                            >
                              <span>{clueObj.number}. </span>
                              <textarea
                                rows={2} 
                                cols={30}
                                placeholder="Enter your clue"
                                value={clueObj.clue}
                                onChange={(e) => handleClueChange(e, index, "down")}
                                className={
                                  style.QuestionContainer
                                }
                              />
                              <input
                                type="text"
                                placeholder="Enter your answer"
                                value={clueObj.answer}
                                onChange={(e) =>
                                  handleClueAnswerChange(e, index, "down")
                                }
                              />
                              <div className={style.crossAction}>
                                <Button
                                  sx={{ width: "fit-content", margin: "4px 0" }}
                                  variant="contained"
                                  onClick={() =>
                                    startSelectingCell(
                                      index,
                                      "down",
                                      clueObj.id
                                    )
                                  }
                                >
                                  Select Starting Cell
                                </Button>

                                <div className={style.delBtn}>
                                  <MdDelete
                                    className={style.delBtnIco}
                                    onClick={() =>
                                      removeClue("down", clueObj.id)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                          <Button
                            sx={{ width: "fit-content" }}
                            variant="contained"
                            endIcon={<MdAddCircleOutline />}
                            color="secondary"
                            onClick={() => addClue("down")}
                          >
                            Add Down Clue
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={style.explanation}>
                <h4>Explanation</h4>
                <div className={style.explanationTextArea}>
                  <ReactQuill
                    modules={modules}
                    formats={formats}
                    theme="snow"
                    value={explanation}
                    onChange={handleExplanationChange}
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {rearrangeWordsView ? (
            <div className={style.fillQuestionSection}>
              <div className={style.questionCreation}>
                <div className={style.creationTop}>
                  <div className={style.creationTopLeft}>
                    <TbMessageQuestion />
                    <p>Enter your question?</p>
                    <span>*</span>
                  </div>
                  <div className={style.actionButtons}>
                    <div className={style.settingsIcon}>
                      <IconButton>
                        <IoSettingsOutline
                          onClick={() => {
                            setSettingView(true);
                          }}
                        />
                      </IconButton>
                    </div>
                    <div className={style.questionClearButton}>
                      <button onClick={handleReArrangeQuestionClear}>
                        Clear
                      </button>
                    </div>
                    <div className={style.questionCreateButton}>
                      <button disabled={isDisabled} onClick={handleReArrangeQuestionCreation}>
                        Save Question
                      </button>
                    </div>
                  </div>
                </div>
                <div className={style.creationBottom}>
                  <ReactQuill
                    modules={modules}
                    formats={formats}
                    theme="snow"
                    value={questionValue}
                    onChange={handleReArrangeQuestionChange}
                    style={{ width: "100%", height: "300px" }}
                  />
                </div>
              </div>

              <div className={style.mainOptionSet}>
                <div className={style.reArrangeOptionSet}>
                  <div className={style.firstOptionTop}>
                    <MdOutlineQuestionAnswer />
                    <p>Enter your option set</p>
                  </div>
                  {reArrangeOptionSet.map((first, index) => (
                    <div className={style.firstOptionSetValues} key={index}>
                      <TextField
                        sx={{ width: "95%" }}
                        required
                        variant="filled"
                        value={first}
                        onChange={(e) =>
                          handleReArrangeOptionSet(index, e.target.value)
                        }
                      />
                      <IconButton
                        aria-label="delete"
                        color="error"
                        onClick={() => handleDeleteReArrangeOption(index)}
                        disabled={reArrangeOptionSet.length <= 2}
                      >
                        <MdDelete />
                      </IconButton>
                    </div>
                  ))}
                  <div className={style.firstOptionAdd}>
                    <Button
                      variant="contained"
                      endIcon={<MdAddCircleOutline />}
                      color="secondary"
                      onClick={() => handleReArrangeOptionAdd()}
                    >
                      Add Option
                    </Button>
                  </div>
                </div>

                <div className={style.reArrangeAnswerKey}>
                  <div className={style.matchAnswerTop}>
                    <MdOutlineQuestionAnswer />
                    <p>Enter your answer Keys</p>
                  </div>
                  {reArrangeAnswerValues.map((answer, index) => (
                    <div className={style.matchAnswerValues} key={index}>
                      <TextField
                        sx={{ width: "95%" }}
                        required
                        variant="filled"
                        value={answer}
                        onChange={(e) =>
                          handleReArrangeAnswerKey(index, e.target.value)
                        }
                      />
                      <IconButton
                        aria-label="delete"
                        color="error"
                        onClick={() => handleDeleteReArrangeAnswer(index)}
                        disabled={reArrangeAnswerValues.length <= 2}
                      >
                        <MdDelete />
                      </IconButton>
                    </div>
                  ))}
                  <div className={style.answerKeyAdd}>
                    <Button
                      variant="contained"
                      endIcon={<MdAddCircleOutline />}
                      color="secondary"
                      onClick={handleAddReArrangeAnswer}
                    >
                      Add Answer
                    </Button>
                  </div>
                </div>
              </div>
              <div className={style.explanation}>
                <h4>Explanation</h4>
                <div className={style.explanationTextArea}>
                  <ReactQuill
                    modules={modules}
                    formats={formats}
                    theme="snow"
                    value={explanation}
                    onChange={handleExplanationChange}
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {selectAnswerView ? (
            <div className={style.fillQuestionSection}>
              <div className={style.questionCreation}>
                <div className={style.creationTop}>
                  <div className={style.creationTopLeft}>
                    <TbMessageQuestion />
                    <p>Enter your question?</p>
                    <span>*</span>
                  </div>
                  <div className={style.actionButtons}>
                    <div className={style.settingsIcon}>
                      <IconButton>
                        <IoSettingsOutline
                          onClick={() => {
                            setSettingView(true);
                          }}
                        />
                      </IconButton>
                    </div>
                    <div className={style.creationTopRight}>
                      <Button
                        variant="contained"
                        onClick={handleAddDropdownClick}
                      >
                        Add Dropdown
                      </Button>
                    </div>
                    <div className={style.questionClearButton}>
                      <button onClick={handleSelectQuestionClear}>Clear</button>
                    </div>
                    <div className={style.questionCreateButton}>
                      <button disabled={isDisabled} onClick={handleSelectQuestionCreation}>
                        Save Question
                      </button>
                    </div>
                  </div>
                </div>

                <div className={style.creationBottomBlank}>
                  <div className={style.textArea}>
                    <ReactQuill
                      ref={selectTextFieldRef}
                      value={selectContent}
                      onChange={(content) => setSelectContent(content)}
                      modules={modules}
                      formats={formats}
                      theme="snow"
                      style={{ width: "100%", height: "300px" }}
                    />
                  </div>
                </div>
              </div>
              <div className={style.optionSet}>
                <div className={style.optionTop}>
                  <MdOutlineQuestionAnswer />
                  <p>Enter your dropdown options</p>
                  <p>(To remove the dropdown use the Remove Dropdown button)</p>
                  <span>*</span>
                </div>
                <div className={style.optionBottomLeft}>
                  {renderDropdowns()}
                </div>
              </div>
              <div className={style.explanation}>
                <h4>Explanation</h4>
                <div className={style.explanationTextArea}>
                  <ReactQuill
                    modules={modules}
                    formats={formats}
                    theme="snow"
                    value={explanation}
                    onChange={handleExplanationChange}
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {highlightView ? (
            <div className={style.fillQuestionSection}>
              <div className={style.questionCreation}>
                <div className={style.creationTop}>
                  <div className={style.creationTopLeft}>
                    <TbMessageQuestion />
                    <p>Enter your question?</p>
                    <span>*</span>
                  </div>
                  <div className={style.actionButtons}>
                    <div className={style.settingsIcon}>
                      <IconButton>
                        <IoSettingsOutline
                          onClick={() => {
                            setSettingView(true);
                          }}
                        />
                      </IconButton>
                    </div>
                    <div className={style.creationTopRight}></div>
                    <div className={style.questionClearButton}>
                      <button onClick={handleHighlightQuestionClear}>
                        Clear
                      </button>
                    </div>
                    <div className={style.questionCreateButton}>
                      <button disabled={isDisabled} onClick={handleHighlightQuestionCreation}>
                        Save Question
                      </button>
                    </div>
                  </div>
                </div>

                <div className={style.creationBottomBlank}>
                  <div className={style.textArea}>
                    <ReactQuill
                      modules={modules}
                      formats={formats}
                      theme="snow"
                      value={questionValue}
                      onChange={handleHighlightQuestionChange}
                      style={{ width: "100%", height: "300px" }}
                    />
                  </div>
                </div>
              </div>
              <div className={style.optionSet}>
                <div className={style.optionTop}>
                  <MdOutlineQuestionAnswer />
                  <p>Drag on the text to select</p>
                  <span>*</span>
                </div>
                <div className={style.optionBottomLeft}>
                <ReactQuill
                  ref={quillRef}
                  value={highlightContent}
                  onChange={(content) => {
                    const sanitizedContent = content
                      .replace(/‘|’/g, "'")
                      .replace(/“|”/g, '"');
                    setHighlightContent(sanitizedContent);
                  }}
                  onChangeSelection={handleSelectionChange} 
                  modules={{
                    toolbar: false, 
                    imageResize: {}  
                  }}
                  style={{ width: "100%", height: "200px" }}
                />
                </div>
              </div>
              <div className={style.explanation}>
                <h4>Explanation</h4>
                <div className={style.explanationTextArea}>
                  <ReactQuill
                    modules={modules}
                    formats={formats}
                    theme="snow"
                    value={explanation}
                    onChange={handleExplanationChange}
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {/*rewrite*/}

          {reWriteView ? (
            <div className={style.fillQuestionSection}>
              <div className={style.questionCreation}>
                <div className={style.creationTop}>
                  <div className={style.creationTopLeft}>
                    <TbMessageQuestion />
                    <p>Enter your question?</p>
                    <span>*</span>
                  </div>
                  <div className={style.actionButtons}>
                    <div className={style.settingsIcon}>
                      <IconButton>
                        <IoSettingsOutline
                          onClick={() => {
                            setSettingView(true);
                          }}
                        />
                      </IconButton>
                    </div>
                    <div className={style.questionClearButton}>
                      <button onClick={handleReWriteQuestionClear}>
                        Clear
                      </button>
                    </div>
                    <div className={style.questionCreateButton}>
                      <button disabled={isDisabled} onClick={handleReWriteQuestionCreation}>
                        Save Question
                      </button>
                    </div>
                  </div>
                </div>
                <div className={style.creationBottom}>
                  <ReactQuill
                    theme="snow"
                    value={questionValue}
                    modules={modules}
                    formats={formats}
                    onChange={handleReWriteQuestionChange}
                    style={{ width: "100%", height: "300px" }}
                  />
                </div>
              </div>
              <div className={style.optionSet}>
                <div className={style.optionTop}>
                  <MdOutlineQuestionAnswer />
                  <p>Enter your answer</p>
                  <span>*</span>
                </div>
                <div className={style.optionBottomLeft}>
                  <TextField
                    fullWidth
                    multiline
                    rows={8} 
                    variant="outlined"
                    value={reWriteAnswer}
                    onChange={(e) => {
                      let sanitizedValue = e.target.value
                        .replace(/‘|’/g, "'")
                        .replace(/“|”/g, '"');
                      setReWriteAnswer(sanitizedValue);
                    }}
                    InputProps={{
                      style: {
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontSize: '13px',
                      },
                    }}
                  />
                </div>
              </div>
              <div className={style.explanation}>
                <h4>Explanation</h4>
                <div className={style.explanationTextArea}>
                  <ReactQuill
                    modules={modules}
                    formats={formats}
                    theme="snow"
                    value={explanation}
                    onChange={handleExplanationChange}
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      {settingView ? (
        <>
          <div className={style.Overlay}></div>
          <div className={style.settingsListing}>
            <div className={style.settingHeader}>
              <div className={style.headerSettingText}>
                <h5>Add your settings</h5>
              </div>

              <div className={style.settingClose}>
                <MdClose
                  onClick={() => {
                    setSettingView(false);
                    setCorrectMark("1");
                    setInCorrectMark("0");
                    setIsCheckedLearner(false);
                    setIsCheckedMentor(false);
                    setIsCheckedOther(false);
                  }}
                />
              </div>
            </div>

            <div className={style.settingSection}>
              {/* <div className={style.mainSection}> */}
              {/* <div className={style.sectionValues}>
                  <label htmlFor="" style={{ color: "#74174e" }}>
                    Comment By Learner
                  </label>
                  <Switch
                    checked={isCheckedLearner}
                    onChange={handleLearnerSwitchChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div> */}
              {/* </div> */}
              {/* <div className={style.mainSection}>
              <div className={style.sectionValues}>
                  <label htmlFor="" style={{ color: "#74174e" }}>
                    Comment By Mentor
                  </label>
                  <Switch
                    checked={isCheckedMentor}
                    onChange={handleMentorSwitchChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>
                <div className={style.sectionValues}>
                  <label htmlFor="" style={{ color: "#74174e" }}>
                    Comment By Others
                  </label>
                  <Switch
                    checked={isCheckedOther}
                    onChange={handleOtherSwitchChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>
              </div> */}
              {/* <div className={style.mainSection}>
                <div className={style.sectionValues}>
                  <label htmlFor="" style={{ color: "green" }}>
                    Correct Mark
                  </label>
                  <input
                    value={correctMark}
                    onChange={(e) => {
                      setCorrectMark(e.target.value);
                    }}
                    type="number"
                    name=""
                    id=""
                    min="0"
                  />
                </div>
                <div className={style.sectionValues}>
                  <label htmlFor="" style={{ color: "red" }}>
                    Incorrect Mark
                  </label>
                  <input
                    value={inCorrectMark}
                    onChange={(e) => {
                      setInCorrectMark(e.target.value);
                    }}
                    type="number"
                    name=""
                    id=""
                    min="0"
                  />
                </div>
              </div> */}

              <div className={style.sectionValues}>
                <label htmlFor="">Status</label>
                <FormControl sx={{ width: "100%", margin: "0.5rem 0" }}>
                  <InputLabel id="demo-simple-select-label">
                    Select Level
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Select Level"
                    value={assessmentLevel}
                    onChange={(e) => {
                      setAssessmentLevel(e.target.value);
                    }}
                  >
                    <MenuItem value={"easy"}>LOTS</MenuItem>
                    <MenuItem value={"hard"}>HOTS</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className={style.sectionValues}>
                <label htmlFor="">Status</label>
                <FormControl sx={{ width: "100%", margin: "0.5rem 0" }}>
                  <InputLabel id="demo-simple-select-label">
                    Select status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Select status"
                    value={assessmentStatus}
                    onChange={(e) => {
                      setAssessmentStatus(e.target.value);
                    }}
                  >
                    <MenuItem value={"draft"}>Draft</MenuItem>
                    <MenuItem value={"publish"}>Publish</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {/* <div className={style.mainSection}>
               
                <div className={style.sectionValues}>
                  
                </div>
              </div> */}
            </div>
            <div className={style.settingButton}>
              <button
                className={style.add}
                onClick={() => {
                  setSettingView(false);
                  setSettingsValidation(true);
                }}
              >
                Add
              </button>
              <button
                className={style.cancel}
                onClick={() => {
                  setSettingView(false);
                  setCorrectMark("1");
                  setInCorrectMark("0");
                  setIsCheckedLearner(false);
                  setIsCheckedMentor(false);
                  setIsCheckedOther(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {/* {whiteBoradIconShow  ? (
        <div className={style.chatboticon}>
          <div onClick={()=>{
        setWhiteBoardShow(true)
        setWhiteBoardIconShow(false)
      }}  className={style.icontext}>
       <BiClipboard className={style.WhiteboardFloatingIcon} /><p> Whiteboard</p>
      </div>
         
        </div>
      ) : (
        ""
      )} */}

      {/* {whiteBoradShow?
      <div  className={`${style.WhiteBoradContainer} ${closing ? style.closing : ''}`}>
        <div className={style.WhiteBoradHead}>
          <div className={style.HeadText}>
            White Board
          </div>
          <div
            className={style.WhiteBoradIcon}
            onClick={() => {
              setTimeout(() => {
                setWhiteBoardIconShow(true)
              setWhiteBoardShow(false)
              }, 500);
              setClosing(true);
            }}
          >
            <AiOutlineClose />
          </div>
        </div>
        <div className={style.WhiteBoradContent} >
        <Whiteboard/>
        
        </div>

      </div>
      :''} */}
      {mediaPopup ? (
        <>
          <div className={style.Overlay}></div>
          <div className={style.MediaPopup}>
            <div className={style.MediaPopupHeader}>
              <div className={style.MediaPopupHeaderText}>
                <h5>Attachment Details</h5>
              </div>

              <div className={style.MediaPopupHeaderClose}>
                <AiOutlineClose
                  onClick={() => {
                    setMediaPopup(false);
                  }}
                />
              </div>
            </div>
            <div className={style.MediaPopupSection}>
              <div className={style.tabSection}>
                <Tabs
                  TabIndicatorProps={{ style: { backgroundColor: "#83b516" } }}
                  value={selectedTab}
                  onChange={handleTabChange}
                  centered
                >
                  <Tab
                    label="Images"
                    sx={{
                      "&.Mui-selected": {
                        color: "#3e61a3",
                        backgroundColor: "",
                      },
                      fontSize: "1rem",
                      color: "#1a1e5d",
                      margin: "0 .5rem",
                      borderRadius: "10px",
                    }}
                  />
                  <Tab
                    label="Input"
                    sx={{
                      "&.Mui-selected": {
                        color: "#3e61a3",
                        backgroundColor: "",
                      },
                      fontSize: "1rem",
                      color: "#1a1e5d",
                      backgroundColor: "",
                      margin: "0 .5rem",
                      borderRadius: "10px",
                    }}
                  />
                </Tabs>
              </div>
              <Typography>
                {selectedTab === 0 && (
                  <div className={style.ImageGRid}>
                    {mediaCollection && mediaCollection.length > 0
                      ? mediaCollection.map((item, index) => {
                          return (
                            <div
                              key={index}
                              onClick={() => {
                                handleImageFirstOptionSet(
                                  selectedImageOptionIndex,
                                  item.file_url
                                );
                                setMediaPopup(false);
                              }}
                              className={style.Card}
                            >
                              <img src={item.file_url} />
                            </div>
                          );
                        })
                      : ""}
                  </div>
                )}
                {selectedTab === 1 && (
                  <div className={style.MediaPopupInputTab}>
                    <button
                      onClick={() => {
                        const input = document.getElementById(
                          `fileInput_${selectedImageOptionIndex}`
                        );
                        console.log();
                        input.click();
                        setMediaPopup(false);
                      }}
                    >
                      Select File
                    </button>
                  </div>
                )}
              </Typography>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default AsessmentsCreation;
